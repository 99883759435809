import React, { FC } from 'react';

const PolygonIcon:FC = () => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="54.7197" cy="54.7194" r="45.1432" transform="rotate(13.9931 54.7197 54.7194)" fill="#8049DB" />
    <path d="M59.4366 46.8296L60.4746 42.664C60.6919 41.7921 60.4342 40.8714 59.7976 40.2363L50.5811 31.0796C49.9224 30.4257 48.9585 30.1801 48.0667 30.4397L35.211 34.1896C34.342 34.4441 33.6704 35.1413 33.4506 36.0233L30.3339 48.5302C30.1135 49.4147 30.3806 50.3511 31.0393 50.9837L40.4825 60.0898C41.1362 60.7211 42.0787 60.9559 42.9541 60.7084L65.678 54.2305C66.5597 53.9791 67.5085 54.2209 68.1628 54.8604L73.4382 60.0265C74.0862 60.6591 74.3471 61.5886 74.1285 62.4656L72.4154 69.3402C72.1962 70.2197 71.5271 70.9175 70.6556 71.1713L63.2856 73.3254C62.3908 73.5869 61.4243 73.3407 60.765 72.6785L55.688 67.5967C55.0564 66.9628 54.8006 66.0453 55.0173 65.1759L56.1106 60.7885L50.5385 62.2906L49.4181 66.7863C49.1971 67.6734 49.4667 68.6104 50.128 69.2436L59.423 78.1709C60.0773 78.7997 61.0148 79.0333 61.8851 78.7845L74.7289 75.1466C75.6099 74.8978 76.2878 74.1969 76.5095 73.3073L79.5558 61.0826C79.7744 60.2057 79.5123 59.2812 78.87 58.6474L69.5079 49.4304C68.8568 48.789 67.9136 48.5459 67.0339 48.7897L44.4231 55.0924C43.5541 55.3362 42.6198 55.1007 41.968 54.4726L36.7475 49.4513C36.085 48.8124 35.8172 47.8678 36.0446 46.977L37.9048 39.7268C38.1309 38.841 38.8157 38.1445 39.7005 37.9019L46.5743 36.0096C47.439 35.7728 48.3657 36.0064 49.0143 36.6257L54.2808 41.6558C54.9597 42.3041 55.2288 43.2758 54.9794 44.1799L53.8179 48.3896L59.4366 46.8296Z" fill="white" />
  </svg>
);

export default PolygonIcon;
