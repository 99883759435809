import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';

const Eco: FC = () => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Styled.Title>{t('ECO_title')}</Styled.Title>
      <Styled.Desktop>
        <Styled.Item rocket>
          <Styled.Icon src="/assets/images/Eco/rocket.svg" />
          <Styled.Descr>{t('ECO_TEXT_rocket')}</Styled.Descr>
        </Styled.Item>
        <Styled.Item earth>
          <Styled.Icon src="/assets/images/Eco/earth.svg" />
          <Styled.Descr>{t('ECO_TEXT_earth')}</Styled.Descr>
        </Styled.Item>
        <Styled.Item star>
          <Styled.Icon src="/assets/images/Eco/star.svg" />
          <Styled.Descr>{t('ECO_TEXT_star')}</Styled.Descr>
        </Styled.Item>
        <Styled.Item wallet>
          <Styled.Icon src="/assets/images/Eco/wallet.svg" />
          <Styled.Descr>{t('ECO_TEXT_wallet')}</Styled.Descr>
        </Styled.Item>
        <Styled.Item nft>
          <Styled.Icon src="/assets/images/Eco/nft.svg" />
          <Styled.Descr>{t('ECO_TEXT_nft')}</Styled.Descr>
        </Styled.Item>
        <Styled.Item profile>
          <Styled.Icon src="/assets/images/Eco/profile.svg" />
          <Styled.Descr>{t('ECO_TEXT_profile')}</Styled.Descr>
        </Styled.Item>
      </Styled.Desktop>
    </Styled.Wrapper>
  );
};

export default Eco;
