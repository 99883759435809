import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

import moment from 'moment';
import Styled from './styled';

SwiperCore.use([Pagination, Autoplay]);

type NewsProps = {
  data: NewsTypes[]
}

type NewsTypes = {
  avatar: string;
  publisher: string;
  text: string;
  link: string;
  date: string;
}
const News:FC<NewsProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Title>{t('NEWS_title')}</Styled.Title>
        <Styled.Cards>
          <Swiper
            className="mySwiper"
            loop
            freeMode
            speed={5000}
            freeModeMomentum
            slidesPerView={1}
            spaceBetween={20}
            initialSlide={0}
            // autoplay={{
            //   delay: 1,
            //   disableOnInteraction: false,
            // }}
            mousewheel={{
              forceToAxis: true,
            }}
            pagination={{
              el: '.news__pagination',
              clickable: true,
              type: 'bullets',
            }}
            breakpoints={{
              751: {
                slidesPerView: 2,
              },
              1051: {
                slidesPerView: 3,
              },
            }}
          >
            {data.map((item) => (
              <SwiperSlide>
                <Styled.Item key={item.publisher}>
                  <Styled.ItemTop>
                    <Styled.Avatar src={item.avatar} alt={item.publisher} />
                    <Styled.Date>
                      { moment(item.date).format('D MMMM').toLowerCase()}
                      {' '}
                      { moment().format('YYYY') !== moment(item.date).format('YYYY')
                        ? moment(item.date).format('YYYY') : moment(item.date).format('YYYY') }
                    </Styled.Date>
                  </Styled.ItemTop>
                  <Styled.Text>{t(`${item.text}`)}</Styled.Text>
                  <Styled.Link href={item.link}>
                    <Styled.SVG />
                  </Styled.Link>
                </Styled.Item>
              </SwiperSlide>
            ))}
            <div className="news__pagination" />
          </Swiper>
        </Styled.Cards>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default News;
