import React, { FC } from 'react';

const OptimismIcon:FC = () => (
  <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M51.273 114.522C79.5903 121.137 107.908 103.543 114.522 75.2258C121.137 46.9086 103.543 18.5909 75.226 11.9765C46.9088 5.36205 18.5911 22.9556 11.9767 51.2729C5.36224 79.5901 22.9558 107.908 51.273 114.522Z" fill="#FF0420" />
    <path d="M45.1154 73.3476C42.4211 72.8382 39.9652 71.4673 38.1176 69.4412C37.3365 68.4929 36.7981 67.3686 36.5491 66.1655C36.3 64.9625 36.3478 63.7169 36.6883 62.5364C36.8627 61.843 37.0892 61.1637 37.366 60.5044C38.1313 58.7331 39.1238 56.6257 40.3434 54.1823C43.7345 47.5375 49.0583 45.0626 56.315 46.7576C58.1408 47.1527 59.8672 47.9148 61.3893 48.9977C62.7467 49.9323 63.7946 51.2504 64.399 52.7836C65.0017 54.3587 65.095 56.0831 64.6656 57.714C64.4928 58.3942 64.2693 59.0605 63.997 59.7073C63.0789 61.8998 62.0993 64.0102 61.0582 66.0385C59.3411 69.342 57.1758 71.608 54.5623 72.8365C51.9571 74.0398 48.8081 74.2101 45.1154 73.3476ZM46.948 67.9343C48.2717 68.2823 49.6769 68.1345 50.8991 67.5187C52.2688 66.7577 53.3559 65.5747 53.9987 64.1458C55.1516 61.8741 56.0911 59.876 56.8171 58.1517C57.0476 57.6304 57.2285 57.0886 57.3573 56.5334C57.9025 54.1995 56.957 52.7484 54.521 52.18C53.1855 51.834 51.7703 51.9783 50.5321 52.5867C49.1808 53.3634 48.1093 54.5469 47.4703 55.9684C46.6073 57.5786 45.6546 59.5736 44.6124 61.9533C44.3838 62.461 44.2058 62.9901 44.0813 63.5329C43.5302 65.892 44.4858 67.3591 46.948 67.9343Z" fill="white" />
    <path d="M62.0504 76.9103C61.9265 76.8889 61.8091 76.8399 61.7067 76.7669C61.6044 76.6939 61.5198 76.5988 61.4593 76.4886C61.3796 76.252 61.3963 75.9935 61.5059 75.7691L72.6541 52.0063C72.7655 51.7414 72.9736 51.5289 73.2361 51.4119C73.482 51.2899 73.7621 51.2553 74.0302 51.3137L84.2621 53.7037C86.6987 54.1482 88.9425 55.3231 90.6959 57.0724C91.4098 57.8214 91.9162 58.7435 92.1654 59.7478C92.4145 60.7521 92.3976 61.8039 92.1165 62.7998C91.9556 63.4722 91.7214 64.1249 91.4181 64.7462C90.3886 67.2388 88.4553 69.2504 86.0056 70.3782C83.753 71.3394 80.947 71.4276 77.5877 70.643L72.3957 69.4302L68.6587 77.4408C68.5472 77.7056 68.3391 77.9182 68.0766 78.0352C67.831 78.157 67.5513 78.1917 67.2834 78.1336L62.0504 76.9103ZM79.0994 65.3967C80.1021 65.6543 81.1618 65.5741 82.1142 65.1684C83.0946 64.7493 83.8825 63.9783 84.3227 63.0073C84.483 62.6433 84.6118 62.2662 84.7074 61.8802C84.8023 61.5724 84.8284 61.2475 84.7837 60.9286C84.739 60.6096 84.6247 60.3044 84.4489 60.0346C83.9178 59.4106 83.1741 59.0057 82.3618 58.8982L77.7464 57.8201L74.6765 64.3636L79.0994 65.3967Z" fill="white" />
  </svg>
);

export default OptimismIcon;
