import styled, { css } from 'styled-components';
import colors from 'styles/colors';
import { default as NotStyledButton } from 'styles/elements/button';
import { default as NotStyledMenu } from 'icons/developments/menu.svg';
import { default as NotStyledCloseMenu } from 'icons/developments/closeMenu.svg';

type MenuTypes = {
  active?: boolean
};

type WrapperType = {
  scrolled?: boolean
  active?: boolean
}

const Wrapper = styled.div`
  padding: 24px 100px;
  display: flex;
  align-items: center;
  background: #F8F8F8;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 14px 20px;
  }
  @media (max-width: 900px) {
    padding: 14px 20px;
    justify-content: space-between;
  }
  
`;

const Container = styled.header<WrapperType>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  background: #F8F8F8;
  
  ${(props) => props.scrolled && css`
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  `};

  @media (max-width: 900px) {
    height: 75px;
  }
  @media (max-width: 600px) {
    box-shadow: none;
  }
`;

const Logo = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 155px;
  width: 100%;
  margin-right: 20px;
  @media (max-width: 600px) {
    max-width: 120px;
  }
`;

const Link = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 85%;
  align-items: center;
  text-decoration: none;
  margin-right: 60px;
  color: ${colors.black};
  transition: all .3s ease;
  position: relative;
  
  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(182, 232, 47, 0.25) 0%, rgba(80, 224, 237, 0.25) 100%);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0 50%;
  }

  &:hover:after {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  
  @media (max-width: 900px) {
    margin-right: 0;
    margin-top: 40px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const Nav = styled.div`
  max-width: 435px;
  width: 100%;
  margin-left: auto;
  display: flex;
  @media (max-width: 900px) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

const Button = styled(NotStyledButton)`
  margin-left: 102px;
  margin-top: 0;
  min-width: 160px;
  background: linear-gradient(90deg, #B6E82F 0%, #50E0ED 100%);
  height: 40px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: ${colors.black};
  text-transform: initial;
  @media (max-width: 1024px) {
    margin-left: 20px;
  }

  @media (max-width: 900px) {
    width: 105px;
    height: 35px;
    display: flex;
    justify-content: center;
    margin-right: 0;
    align-items: center;
    padding: 0;
    font-style: normal;
    font-size: 12px;
    line-height: 7px;
    margin-left: auto;
  }
  
  @media (max-width: 600px) {
    min-width: 150px;
    height: 32px;
    font-size: 14px;
    line-height: 145%;
  }
`;

const MobWrapper = styled.div<WrapperType>`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: white;
  z-index: 10000;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease;
  
  ${(props) => props.scrolled && css`
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  `};
  
  ${(props) => props.active && css`
    display: none;
  `};
  
  @media (max-width: 600px) {
    box-shadow: none;
  }
`;

const MenuIcon = styled(NotStyledMenu)`
  cursor: pointer;
`;

const MenuCloseIcon = styled(NotStyledCloseMenu)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Menu = styled.div<MenuTypes>`
  width: 250px;
  min-height: 100vh;
  background: white;
  position: fixed;
  left: -250px;
  z-index: 10001;
  padding: 50px 0 0 20px;
  transition: left 0.5s ease;
  ${(props) => props.active && css`
    top: 0;
    left: 0;
  `}
`;

const Bg = styled.img<MenuTypes>`
  opacity: 0;
  transition: all 0.3s ease;
  ${(props) => props.active && css`
    background: gray;
    z-index: 10000;
    position: fixed;
    min-height: 100vh;
    min-width: 100vh;
    opacity: 0.5;
  `}
`;

export default {
  Wrapper,
  Container,
  MobWrapper,
  Logo,
  Nav,
  Link,
  Button,
  Menu,
  MenuIcon,
  Bg,
  MenuCloseIcon,
};
