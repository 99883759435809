import React, {
  FC, useState, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

import Styled from './styled';
import useResize from '../../hooks/useResize';

type HeroDataProps = {
  data: IconBox[]
}

type IconBox = {
  link: string,
  id: number,
  icon: string,
}
const Hero:FC<HeroDataProps> = ({ data }) => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Container show={show}>
        {!mobileView
          ? (
            <>
              <Styled.CoinBox show={show}>
                <Styled.Polygon />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Aurora />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Avalanche />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Bitgert />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Ethereum />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Arbitrum />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.Optimism />
              </Styled.CoinBox>
              <Styled.CoinBox show={show}>
                <Styled.BNB />
              </Styled.CoinBox>
            </>
          )
          : null}
        <Styled.Title show={show}>{HTMLReactParser(t('HERO_title'))}</Styled.Title>
        <Styled.Subtitle show={show}>{HTMLReactParser(t('HERO_subtitle'))}</Styled.Subtitle>
        <Styled.Box>
          <Styled.ButtonBox>
            <Styled.Button href="https://app.roobee.finance/">
              {t('BUTTON_launch')}
            </Styled.Button>
            <Styled.Button
              white
              href="https://roobee.gitbook.io/roobee-eng/about-roobee.finance"
            >
              {t('BUTTON_docs')}
            </Styled.Button>
          </Styled.ButtonBox>
          <Styled.IconBox>
            <Styled.IconWrapper>
              <Styled.IconText>{t('HERO_checkout')}</Styled.IconText>
              <Styled.Icons>
                {data.map((item) => (
                  <Styled.Link target="_blank" href={`${item.link}`}>
                    <Styled.Icon src={item.icon} />
                  </Styled.Link>
                ))}
              </Styled.Icons>
            </Styled.IconWrapper>
          </Styled.IconBox>
        </Styled.Box>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Hero;
