import styled, { css } from 'styled-components';
import { default as ContainerNotStyled } from 'styles/elements/container';

type CardsRowLinkProps = {
  slug: string
}

const Wrapper = styled.section`
  margin-top: 90px;
  margin-bottom: 230px;
  position: relative;
  z-index: 1;
  
  @media (max-width: 1350px) {
    margin-top: 0;
  }
  
  @media (max-width: 900px) {
    margin: 50px auto;
  }
`;
const Container = styled(ContainerNotStyled)`
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-family: 'Gruppo', sans-serif;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 65px;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
const Websites = styled.div`
  margin-bottom: 102px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 48px;
  color: #8596A6;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
`;
const WebsitesHr = styled.span`
  margin: 0 16px;
`;
const WebsitesLink = styled.a`
  font-weight: 300;
  font-size: 24px;
  line-height: 48px;
  color: #5C5C5C;
  transition: color .2s ease;

  &:hover {
    color: #181818;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 25px;
  }
  
  @media (max-width: 480px) {
    white-space: nowrap;

    &:nth-child(3) {
      width: 130px;
      display: inline-block;
    }
  }
  @media (max-width: 400px) {
    &:nth-child(3) {
      width: unset;
    }
  }
`;
const Cards = styled.div`
  width: 100%;
  @media (max-width: 500px) {
    padding: 0;
  }
`;
const CardsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const CardsRowLink = styled.a<CardsRowLinkProps>`
  max-width: 310px;
  width: 100%;
  align-items: center;
  display: flex;
  margin-right: 40px;
  margin-top: auto;
  ${(props) => {
    switch (props.slug) {
      case 'gate-io':
        return css`
          height: 50px;
          @media (max-width: 900px) {
            height: 40px;
          }
          @media (max-width: 700px) {
            height: 20px;
          }
        `;
      case 'apeswap':
        return css`
          height: 99px;
          @media (max-width: 900px) {
            height: 80px;
          }
          @media (max-width: 700px) {
            height: 40px;
          }
        `;
      case 'kucoin':
        return css`
          height: 50px;
          @media (max-width: 900px) {
            height: 44px;
          }
          @media (max-width: 700px) {
            height: 22px;
          }
        `;
      case 'exmo':
        return css`
          height: 40px;
          @media (max-width: 900px) {
            height: 34px;
          }
          @media (max-width: 700px) {
            height: 17px;
          }
        `;
      case 'hitbtc':
        return css`
          height: 80px;
          @media (max-width: 900px) {
            height: 50px;
          }
          @media (max-width: 700px) {
            height: 25px;
          }
        `;
      case 'bitforex':
        return css`
          height: 40px;
          @media (max-width: 900px) {
            height: 30px;
          }
          @media (max-width: 700px) {
            height: 15px;
          }
        `;
      default: return '';
    }
  }}
`;
const CardsImage = styled.img`
  height: 100%;
  object-fit: contain;
`;

const HrBox = styled.div`
  white-space: nowrap;
`;

export default {
  Wrapper,
  Container,
  Content,
  Title,
  Websites,
  WebsitesHr,
  WebsitesLink,
  Cards,
  CardsRow,
  CardsRowLink,
  CardsImage,
  HrBox,
};
