import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Liqudity:FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.AnimatedImg index={1} src="./assets/images/liqudity/liquidity_bg_left.png" alt="31" />
        <Styled.AnimatedImg index={2} src="./assets/images/liqudity/liquidity_bg_right.png" alt="31" />
        <Styled.Box>
          <Styled.Title>{t('LIQUDITY_title')}</Styled.Title>
          <Styled.Subtitle>{HTMLReactParser(t('LIQUDITY_subtitle'))}</Styled.Subtitle>
        </Styled.Box>
        <Styled.Box>
          {!mobileView
            ? <Styled.Desktop src="./assets/images/liqudity/desktop.png" />
            : <Styled.Desktop src="./assets/images/liqudity/desktop_tablet.png" />}
          <Styled.Button href="https://app.roobee.finance/liquidity" target="_blank">
            {t('BUTTON_liqudity')}
          </Styled.Button>
        </Styled.Box>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Liqudity;
