import React, { FC } from 'react';

const BitgerIcon:FC = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44.4112" cy="44.362" r="44.3514" fill="url(#paint0_linear_6640_90511)" />
    <path d="M29.6221 67.4201L29.6228 64.3239C29.6229 63.9185 29.8357 63.5429 30.1833 63.3344L35.137 60.3638C35.9063 59.9024 36.8848 60.4568 36.8846 61.3538L36.884 63.7564C36.8839 64.1126 37.2701 64.3347 37.5779 64.1554L54.7591 54.151C55.0645 53.9732 55.065 53.5322 54.76 53.3537L52.8606 52.2421C52.101 51.7976 52.0986 50.7004 52.8563 50.2526L58.1596 47.1179C58.4712 46.9337 58.8617 46.9486 59.1584 47.156L63.1793 49.9665C64.0444 50.5712 64.5597 51.5603 64.5594 52.6159L64.5588 55.2909C64.5585 56.4385 63.9496 57.4999 62.959 58.0793L37.664 72.8763C37.3102 73.0832 36.9078 73.1922 36.498 73.1921L35.3911 73.1919C32.2042 73.1911 29.6213 70.607 29.6221 67.4201Z" fill="white" />
    <path d="M31.0199 28.7067L45.5585 37.3685C46.3421 37.8354 46.2999 38.9839 45.4842 39.3921L40.9455 41.6628C40.4327 41.9194 39.8223 41.885 39.3417 41.5724L37.6213 40.4535C37.3107 40.2516 36.9008 40.4792 36.908 40.8495L37.0048 45.794C37.0117 46.1461 37.3943 46.3611 37.6986 46.1839L54.9061 36.1642C55.2091 35.9878 55.2126 35.5513 54.9125 35.3701L37.5948 24.9111C37.2872 24.7253 36.8946 24.9468 36.8945 25.3062L36.894 27.1824C36.8938 28.2639 35.7094 28.9278 34.7867 28.3636L30.1856 25.5503C29.8426 25.3406 29.6335 24.9675 29.6336 24.5654L29.6342 21.9941C29.635 18.4248 32.5293 15.5319 36.0986 15.5328L36.2676 15.5328C36.6796 15.5329 37.084 15.6433 37.4389 15.8524L62.2923 30.497C63.701 31.3271 64.5655 32.8403 64.5651 34.4754L64.5648 35.803C64.5644 37.4437 63.6932 38.9609 62.2764 39.7883L31.0142 58.0447C30.3987 58.4041 29.6254 57.9602 29.6254 57.2474L29.624 29.4999C29.624 28.7835 30.4044 28.3401 31.0199 28.7067Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_6640_90511"
        x1="13.3652"
        y1="11.8376"
        x2="71.0221"
        y2="76.8864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#760AE7" />
        <stop offset="0.492708" stopColor="#480FDF" />
        <stop offset="1" stopColor="#23B9BF" />
      </linearGradient>
    </defs>
  </svg>

);

export default BitgerIcon;
