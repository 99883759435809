import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Dashboard:FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.BGleft src="./assets/images/dashboard/dashboard_bg_left.png" alt="BG" />
        <Styled.BGright src="./assets/images/dashboard/dashboard_bg_right.png" alt="BG" />
        <Styled.Box>
          <Styled.Title>{t('DASHBOARD_title')}</Styled.Title>
          <Styled.Subtitle>{t('DASHBOARD_subtitle')}</Styled.Subtitle>
        </Styled.Box>
        <Styled.Box>
          {!mobileView
            ? <Styled.Desktop src="./assets/images/dashboard/desktop.png" />
            : <Styled.Desktop src="./assets/images/dashboard/desktop_tablet.png" />}
          <Styled.Button href="https://app.roobee.finance/" target="_blank">{t('BUTTON_dashboard')}</Styled.Button>
        </Styled.Box>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Dashboard;
