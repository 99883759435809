import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 0;
  @media (max-width: 1350px) {
    margin: 0 auto;
  }
`;

const Container = styled(NotStyledContainer)`
`;

const Box = styled.div`
  &:last-of-type {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  @media (max-width: 1350px) {
    &:last-of-type {
      flex-direction: column;
    }
  }
`;

const Desktop = styled.img`
  
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  text-align: start;
  color: ${Colors.black};
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  text-align: start;
  max-width: 548px;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

const Button = styled(NotStyledButton)`
  position: relative;
  z-index: 10;
`;

const IconsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  position: relative;
  z-index: 10 ;
`;

const IconBox = styled.div`
  max-width: 145px;
  width: 100%;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  
  span {
    margin-top: 15px;
    max-width: 105px;
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #000000;
  }
  
  @media (max-width: 1350px) {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: unset;
  }

  @media (max-width: 600px) {
    min-width: unset;
    a {
      img {
        max-width: 50px;
      }
    }
  }
  
`;

const AnimatedImg = styled.img<{ animated: boolean }>`
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease;
  max-width: 825px;
  left: -69%;
  bottom: -63%;
  
  @media (max-width: 1350px) {
    max-width: 100%;
    left: 6%;
    bottom: -74%;
  }
  
  @media (max-width: 600px) {
    left: 0;
    top: 0;
  }
  
  ${(props) => props.animated && css`
    opacity: 1;
  `}
  
`;

export default {
  Wrapper,
  Container,
  Box,
  Desktop,
  Title,
  Subtitle,
  Button,
  IconsWrapper,
  IconBox,
  AnimatedImg,
};
