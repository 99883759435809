import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 100px;
  @media (max-width: 1350px) {
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    padding:  0 20px;
  }
`;

export default Container;
