import React from 'react';
import Styled from './styled';

interface PreloaderProps {
  loading: boolean;
}

const Preloader: React.FC<PreloaderProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  const bodyStyle = loading ? { overflow: 'hidden' } : {};

  return (
    <div style={bodyStyle}>
      <Styled.Wrapper>
        <Styled.Box>
          <Styled.Border />
          <Styled.Symbol />
        </Styled.Box>
      </Styled.Wrapper>
    </div>
  );
};

export default Preloader;
