const roobeeTokenWebsites = [
  {
    title: 'tce', // i18n slug
    url: 'https://etherscan.io/token/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa',
  },
  {
    title: 'tv',
    url: 'https://www.tradingview.com/symbols/ROOBEEBTC/?exchange=HITBTC',
  },
  {
    title: 'tcbsc',
    url: 'https://bscscan.com/token/0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe',
  },
];

const roobeeTokenCards = [
  {
    title: 'Gate.io',
    slug: 'gate-io',
    url: 'https://www.gate.io/trade/ROOBEE_USDT',
  },
  {
    title: 'KuCoin',
    slug: 'kucoin',
    url: 'https://app.uniswap.org/#/add/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa/ETH',
  },
  {
    title: 'BitForex',
    slug: 'bitforex',
    url: 'https://www.bitforex.com/en/spot/roobee_btc',
  },
  {
    title: 'HitBTC',
    slug: 'hitbtc',
    url: 'https://hitbtc.com/exchange/ROOBEE-to-BTC',
  },
  {
    title: 'EXMO',
    slug: 'exmo',
    url: 'https://exmo.me/en/trade/ROOBEE_BTC',
  },
  {
    title: 'ApeSwap',
    slug: 'apeswap',
    url: 'https://apeswap.finance/swap',
  },
];

export {
  roobeeTokenWebsites,
  roobeeTokenCards,
};
