import styled from 'styled-components';
import { default as NotStyledBorder } from './_resources/border';
import { default as NotStyledSymbol } from './_resources/symbol';

const Wrapper = styled.div`
  background-color: #FFFFFF;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
`;

const Box = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Border = styled(NotStyledBorder)`
  
  animation: rotate 3s linear infinite;
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;

const Symbol = styled(NotStyledSymbol)`
  position: absolute;
  top: 33%;
  left: 39%;
`;

export default {
  Wrapper,
  Box,
  Border,
  Symbol,
};
