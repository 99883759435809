import React, { FC, useState, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import GlobalStyle from 'styles/global';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import CookiesPrivacyPolicy from 'components/CookiesPrivacyPolicy';
import { Route, Routes } from 'react-router-dom';
import Main from 'pages/main';
import Howtoinstall from 'pages/howtoinstall';
import Preloader from 'components/Preloader';
import i18n from '../i18n';

const App: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = (): void => {
      setLoading(false);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'; // Устанавливаем overflow: hidden
    } else {
      document.body.style.overflow = ''; // Восстанавливаем значение по умолчанию
    }
  }, [loading]);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="howtoinstall" element={<Howtoinstall />} />
        </Routes>
        <CookiesPrivacyPolicy />
        <Preloader loading={loading} />
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
