import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  margin: 40px auto 0;
  min-height: 750px;
  position: relative; 
  @media (max-width: 1350px) {
    min-height: unset;
    margin: 0 auto;
  }
`;

const Container = styled(NotStyledContainer)`
`;

const Content = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  max-width: 1155px;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  text-align: start;
  @media (max-width: 1350px) {
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  color: ${Colors.black};
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  max-width: 610px;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Button = styled(NotStyledButton)`
`;

const MobileImg = styled.img`
  position: relative;
  z-index: 100;
  @media (max-width: 1350px) {
    margin: 15px auto 0;
    display: flex;
  }
  @media (max-width: 600px) {
    max-height: 300px;
  }
`;

const AnimatedBox = styled.div`
  position: relative;
`;

const AnimatedImg = styled.img<{ index: number, animated: boolean, isScrollingDown: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid #858585;
  border-radius: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-out;

  ${(props) => {
    if (props.index === 1) {
      return css`
        bottom: -95px;
        left: -60%;
        max-width: 149px;
        z-index: 3;
        ${props.animated && css`
          animation: ball-1 10s ease-in-out infinite;
        `}
        
      `;
    }
    if (props.index === 2) {
      return css`
        bottom: 21%;
        left: -80%;
        max-width: 97px;
        z-index: 2;
        ${props.animated && css`
          animation: ball-2 10s ease-in-out infinite;
        `}
      `;
    }
    if (props.index === 3) {
      return css`
        bottom: 17%;
        left: -28%;
        max-width: 74px;
        z-index: 1;
        ${props.animated && css`
          animation: ball-3 10s ease-in-out infinite;
        `}
        
      `;
    }
    if (props.index === 4) {
      return css`
        bottom: 27%;
        left: 85%;
        max-width: 148px;
        z-index: 1;
        //animation: ball-4 10s ease-in-out infinite;
        
      `;
    }
    if (props.index === 5) {
      return css`
        bottom: 7%;
        left: 75%;
        max-width: 63px;
        z-index: 1;
        ${props.animated && css`
          animation: ball-5 10s ease-in-out infinite;
        `}
        
      `;
    }
    if (props.index === 6) {
      return css`
        top: 3%;
        left: 2%;
        max-width: 91px;
        z-index: 1;
        ${props.animated && css`
          animation: ball-6 10s ease-in-out infinite;
        `}
        
      `;
    }
    if (props.index === 7) {
      return css`
        top: 9%;
        left: -22%;
        max-width: 47px;
        z-index: 1;
        ${props.animated && css`
          animation: ball-7 10s ease-in-out infinite;
        `}
        
      `;
    }
    if (props.index === 8) {
      return css`
        top: 22%;
        left: -8%;
        max-width: 42px;
        z-index: 1;
        ${props.animated && css`
          animation: ball-8 10s ease-in-out infinite;
        `}
        
      `;
    }
    return '';
  }};

  @keyframes ball-1 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translateY(-50px);
    }
  };

  @keyframes ball-2 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(70px, 70px);
    }
  };

  @keyframes ball-3 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(-70px, 40px);
    }
  };

  @keyframes ball-4 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(-70px, 50px);
    }
  }

  @keyframes ball-5 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(10px, -70px);
    }
  }

  @keyframes ball-6 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(-50px, 0);
    }
  }

  @keyframes ball-7 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(25px, 0);
    }
  }

  @keyframes ball-8 {
    0%,
    100% {
      transform: translate(0);
    }

    50% {
      transform: translate(-20px, -50px);
    }
  }
`;

export default {
  Wrapper,
  Container,
  Content,
  Box,
  Title,
  Subtitle,
  MobileImg,
  Button,
  AnimatedBox,
  AnimatedImg,
};
