import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DiscordIcon from 'icons/discord_footer.svg';
import moment from 'moment';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Footer:FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 650, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.Top>
        <Styled.TopContainer>
          {mobileView
            ? (
              <>
                <Styled.Column>
                  <Styled.ColumnName>Community</Styled.ColumnName>
                  <Styled.Box>
                    <Styled.Social href="https://t.me/roobee_chat" target="_blank">
                      <Styled.Icon src="/assets/images/footer/telegram.svg" />
                      <span>Telegram</span>
                    </Styled.Social>
                    <Styled.Social href="https://twitter.com/roobee_invest" target="_blank">
                      <Styled.Icon src="/assets/images/footer/twitter.svg" />
                      <span>Twitter</span>
                    </Styled.Social>
                    <Styled.Social href="https://medium.com/roobee-invest" target="_blank">
                      <Styled.Icon src="/assets/images/footer/medium.svg" />
                      <span>Medium</span>
                    </Styled.Social>
                    <Styled.Social href="https://medium.com/roobee-invest" target="_blank">
                      <Styled.Icon src="/assets/images/footer/discord.svg" />
                      <span>Discord</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
                <Styled.Column>
                  <Styled.Box>
                    <Styled.ColumnName>About</Styled.ColumnName>
                    <Styled.Social href="https://coinmarketcap.com/community/profile/Roobee" target="_blank">
                      <span>Blog</span>
                    </Styled.Social>
                    <Styled.Social
                      href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                      target="_blank"
                    >
                      <span>FAQ</span>
                    </Styled.Social>
                  </Styled.Box>
                  <Styled.Box>
                    <Styled.ColumnName>Documents</Styled.ColumnName>
                    <Styled.Social href="/docs/privacy_policy.pdf" target="_blank">
                      <span>{t('FOOTER_Privacy')}</span>
                    </Styled.Social>
                    <Styled.Social href="/docs/terms_of_use.pdf" target="_blank">
                      <span>{t('FOOTER_Service')}</span>
                    </Styled.Social>
                  </Styled.Box>
                  <Styled.Box>
                    <Styled.ColumnName>Contacts</Styled.ColumnName>
                    <Styled.Social href="mailto:support@roobee.io" target="_blank">
                      <span>support@roobee.io</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
              </>
            )
            : (
              <>
                <Styled.Column>
                  <Styled.ColumnName>Community</Styled.ColumnName>
                  <Styled.Box>
                    <Styled.Social href="https://t.me/roobee_chat" target="_blank">
                      <Styled.Icon src="/assets/images/footer/telegram.svg" />
                      <span>Telegram</span>
                    </Styled.Social>
                    <Styled.Social href="https://twitter.com/roobee_invest" target="_blank">
                      <Styled.Icon src="/assets/images/footer/twitter.svg" />
                      <span>Twitter</span>
                    </Styled.Social>
                    <Styled.Social href="https://medium.com/roobee-invest" target="_blank">
                      <Styled.Icon src="/assets/images/footer/medium.svg" />
                      <span>Medium</span>
                    </Styled.Social>
                    <Styled.Social href="https://discord.gg/HTU3BpFNc3" target="_blank">
                      <Styled.Icon src="/assets/images/footer/discord.svg" />
                      <span>Discord</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
                <Styled.Column>
                  <Styled.ColumnName>About</Styled.ColumnName>
                  <Styled.Box>
                    <Styled.Social href="https://coinmarketcap.com/community/profile/Roobee" target="_blank">
                      <span>Blog</span>
                    </Styled.Social>
                    <Styled.Social
                      href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                      target="_blank"
                    >
                      <span>FAQ</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
                <Styled.Column>
                  <Styled.ColumnName>Documents</Styled.ColumnName>
                  <Styled.Box>
                    <Styled.Social href="/docs/privacy_policy.pdf" target="_blank">
                      <span>{t('FOOTER_Privacy')}</span>
                    </Styled.Social>
                    <Styled.Social href="/docs/terms_of_use.pdf" target="_blank">
                      <span>{t('FOOTER_Service')}</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
                <Styled.Column>
                  <Styled.ColumnName>Contacts</Styled.ColumnName>
                  <Styled.Box>
                    <Styled.Social href="mailto:support@roobee.io" target="_blank">
                      <span>support@roobee.io</span>
                    </Styled.Social>
                  </Styled.Box>
                </Styled.Column>
              </>
            )}
        </Styled.TopContainer>
        <Styled.Copyright>{t('FOOTER_Copyright', { year: moment().format('YYYY') })}</Styled.Copyright>
      </Styled.Top>
      <Styled.Bottom>
        <Styled.Text>{t('FOOTER_Text')}</Styled.Text>
        <Styled.DiscordButton href="https://discord.com/invite/HTU3BpFNc3" target="_blank">
          <Styled.ButtonText>{t('FOOTER_Button')}</Styled.ButtonText>
          <DiscordIcon />
        </Styled.DiscordButton>
      </Styled.Bottom>
    </Styled.Wrapper>
  );
};

export default Footer;
