import React, { FC } from 'react';

const BNBIcon:FC = () => (
  <svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6640_90496)">
      <path d="M41.0957 106.572C63.3909 120.788 92.989 114.238 107.205 91.943C121.421 69.6478 114.871 40.0498 92.576 25.8339C70.2808 11.618 40.6828 18.1676 26.4669 40.4627C12.251 62.7579 18.8006 92.356 41.0957 106.572Z" fill="#EBB50B" />
      <path d="M53.1785 50.75L54.9247 58.6338L47.0402 60.3769L45.2941 52.4932L53.1785 50.75Z" fill="white" />
      <path d="M69.8933 61.4062L71.6394 69.29L63.755 71.0331L62.009 63.1494L69.8933 61.4062Z" fill="white" />
      <path d="M86.6103 72.0665L88.3563 79.9502L80.4719 81.6934L78.7258 73.8096L86.6103 72.0665Z" fill="white" />
      <path d="M80.6062 44.6992L85.3859 66.2795L77.5015 68.0227L72.7218 46.4424L80.6062 44.6992Z" fill="white" />
      <path d="M80.6242 44.713L59.0421 49.4845L60.7882 57.3682L82.3703 52.5968L80.6242 44.713Z" fill="white" />
      <path d="M53.1495 87.7628L74.7316 82.9913L72.9856 75.1075L51.4035 79.879L53.1495 87.7628Z" fill="white" />
      <path d="M53.1675 87.7727L48.3879 66.1924L56.2722 64.4492L61.0519 86.0295L53.1675 87.7727Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_6640_90496">
        <rect width="95.7541" height="95.7541" fill="white" transform="translate(52.207 0.09375) rotate(32.5225)" />
      </clipPath>
    </defs>
  </svg>

);

export default BNBIcon;
