import React, { FC } from 'react';

const PreloaderBorder:FC = (props) => (
  <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7027_89920">
      <path d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM24 60C24 79.8822 40.1177 96 60 96C79.8822 96 96 79.8822 96 60C96 40.1177 79.8822 24 60 24C40.1177 24 24 40.1177 24 60Z" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_7027_89920)">
      <circle cx="60" cy="60" r="60" fill="#FFF500" />
      <path fillRule="evenodd" clipRule="evenodd" d="M95.9999 59.5882C87.4465 44.7716 71.4378 34.8 53.1 34.8C25.7619 34.8 3.6 56.9619 3.6 84.3C3.6 84.3373 3.60004 84.3747 3.60012 84.412C-0.59792 77.1398 -3 68.7005 -3 59.7002C-3 32.3621 19.1619 10.2002 46.5 10.2002C73.8008 10.2002 95.9395 32.3016 95.9999 59.5882Z" fill="url(#paint0_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M91.2321 42.1727C76.416 33.6164 57.5649 32.9846 41.6831 42.154C18.0076 55.8231 9.8958 86.0968 23.5648 109.772C23.5832 109.804 23.6016 109.836 23.6201 109.868C16.3494 105.669 10.0504 99.5618 5.55078 91.7682C-8.11827 68.0927 -0.00644612 37.8189 23.669 24.1499C47.3127 10.4992 77.5368 18.5709 91.2321 42.1727Z" fill="url(#paint1_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M77.2717 28.2938C60.1635 28.2929 43.5235 37.1712 34.3547 53.0521C20.6856 76.7275 28.7975 107.001 52.4729 120.67C52.5051 120.689 52.5373 120.707 52.5696 120.726C44.1726 120.726 35.6628 118.586 27.8683 114.086C4.19277 100.417 -3.91905 70.1432 9.75 46.4677C23.4005 22.8244 53.6105 14.7025 77.2717 28.2938Z" fill="url(#paint2_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58.3872 24.0001C43.5711 32.5536 33.6 48.5621 33.6 66.8994C33.6 94.2375 55.7619 116.399 83.1 116.399C83.1376 116.399 83.1752 116.399 83.2128 116.399C75.9404 120.598 67.5008 123 58.5 123C31.1619 123 9 100.838 9 73.5C9 46.1995 31.101 24.061 58.3872 24.0001Z" fill="url(#paint3_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.991 29.2893C32.4358 44.1051 31.8045 62.9551 40.9734 78.8362C54.6425 102.512 84.9162 110.624 108.592 96.9545C108.624 96.936 108.656 96.9175 108.688 96.8989C104.489 104.17 98.3814 110.47 90.587 114.97C66.9115 128.639 36.6378 120.527 22.9688 96.852C9.31819 73.2085 17.3896 42.9846 40.991 29.2893Z" fill="url(#paint4_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.9008 42.4937C28.8988 59.6031 37.7772 76.2445 53.6591 85.414C77.3346 99.083 107.608 90.9712 121.277 67.2957C121.295 67.2644 121.313 67.2331 121.331 67.2018C121.33 75.5977 119.191 84.1063 114.691 91.8998C101.022 115.575 70.7486 123.687 47.0731 110.018C23.4289 96.3671 15.3073 66.1553 28.9008 42.4937Z" fill="url(#paint5_linear_7027_89920)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 59.6237C32.5535 74.4403 48.5622 84.4119 66.9 84.4119C94.2381 84.4119 116.4 62.25 116.4 34.9119C116.4 34.8746 116.4 34.8373 116.4 34.7999C120.598 42.0721 123 50.5114 123 59.5117C123 86.8498 100.838 109.012 73.5 109.012C46.1992 109.012 24.0605 86.9103 24.0001 59.6237Z" fill="url(#paint6_linear_7027_89920)" />
      <mask id="mask1_7027_89920">
        <path d="M59.9959 120C93.133 120 119.996 93.1371 119.996 60C119.996 26.8629 93.133 0 59.9959 0C40.0228 0 22.3291 9.75926 11.4232 24.7693C20.3865 15.7698 32.7908 10.2 46.4959 10.2C73.7968 10.2 95.9355 32.3015 95.9958 59.5882C87.4425 44.7716 71.4337 34.8 53.0959 34.8C26.9203 34.8 5.48997 55.1173 3.71484 80.8421C12.1846 103.705 34.1873 120 59.9959 120Z" fill="black" />
      </mask>
      <g mask="url(#mask1_7027_89920)">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.6122 77.5987C43.4282 86.1547 62.279 86.7864 78.1607 77.6171C101.836 63.948 109.948 33.6743 96.2789 9.99883C96.2604 9.9667 96.2418 9.93459 96.2232 9.90251C103.494 14.1013 109.793 20.2085 114.293 28.0023C127.962 51.6778 119.85 81.9516 96.1747 95.6206C72.5314 109.271 42.3077 101.2 28.6122 77.5987Z" fill="url(#paint7_linear_7027_89920)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M41.8956 90.948C59.0041 90.9491 75.6443 82.0709 84.8133 66.1897C98.4823 42.5143 90.3705 12.2405 66.695 -1.42851C66.6631 -1.44697 66.6311 -1.46538 66.5991 -1.48376C74.9958 -1.48322 83.5053 0.656168 91.2997 5.15625C114.975 18.8253 123.087 49.099 109.418 72.7745C95.7674 96.418 65.5569 104.54 41.8956 90.948Z" fill="url(#paint8_linear_7027_89920)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.2211 95.9999C75.0378 87.4465 85.0094 71.4378 85.0094 53.1C85.0094 25.7619 62.8475 3.6 35.5094 3.6C35.4721 3.6 35.4349 3.60004 35.3976 3.60012C42.6697 -0.597921 51.109 -3 60.1094 -3C87.4475 -3 109.609 19.1619 109.609 46.5C109.609 73.8008 87.5078 95.9396 60.2211 95.9999Z" fill="url(#paint9_linear_7027_89920)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M78.7942 90.6306C87.3494 75.8148 87.9807 56.9649 78.8117 41.0838C65.1427 17.4083 34.8689 9.29648 11.1935 22.9655C11.1614 22.984 11.1294 23.0026 11.0974 23.0211C15.2962 15.7496 21.4038 9.4498 29.1981 4.94971C52.8736 -8.71934 83.1474 -0.60752 96.8164 23.068C110.467 46.7114 102.396 76.9352 78.7942 90.6306Z" fill="url(#paint10_linear_7027_89920)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M91.5445 77.2744C91.5465 60.165 82.6682 43.5235 66.7862 34.3541C43.1107 20.6851 12.837 28.7969 -0.832032 52.4724C-0.850094 52.5036 -0.868119 52.5349 -0.886103 52.5662C-0.885119 44.1704 1.25429 35.6618 5.75391 27.8683C19.423 4.19277 49.6967 -3.91905 73.3722 9.75C97.0164 23.401 105.138 53.6128 91.5445 77.2744Z" fill="url(#paint11_linear_7027_89920)" />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7027_89920"
        x1="-3"
        y1="10.2002"
        x2="96"
        y2="10.2002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6E82F" />
        <stop offset="1" stopColor="#97CF00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7027_89920"
        x1="54.6007"
        y1="8.69991"
        x2="-1.49891"
        y2="56.4002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6E82F" />
        <stop offset="1" stopColor="#97CF00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7027_89920"
        x1="10.2"
        y1="59.3995"
        x2="83.7"
        y2="17.6994"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6E82F" />
        <stop offset="1" stopColor="#FFF500" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_7027_89920"
        x1="36.3004"
        y1="30.9002"
        x2="29.4005"
        y2="114.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6E82F" />
        <stop offset="1" stopColor="#97CF00" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_7027_89920"
        x1="50.0974"
        y1="119.399"
        x2="38.3976"
        y2="34.4993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50E0ED" />
        <stop offset="1" stopColor="#00C7D9" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_7027_89920"
        x1="39.3048"
        y1="93.2992"
        x2="89.1048"
        y2="112.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50E0ED" />
        <stop offset="1" stopColor="#00C5D7" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_7027_89920"
        x1="106.591"
        y1="96.7911"
        x2="27.2153"
        y2="64.4736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50E0ED" />
        <stop offset="1" stopColor="#78F4FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_7027_89920"
        x1="77.8115"
        y1="93.9887"
        x2="119.079"
        y2="60.3105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50E0ED" />
        <stop offset="1" stopColor="#00C5D7" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_7027_89920"
        x1="65.999"
        y1="92.7004"
        x2="98.6989"
        y2="17.1004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7DF00" />
        <stop offset="1" stopColor="#FFF500" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_7027_89920"
        x1="74.6975"
        y1="2.7"
        x2="104.098"
        y2="52.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7DF00" />
        <stop offset="1" stopColor="#FFF500" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_7027_89920"
        x1="92.3632"
        y1="70.6329"
        x2="43.2417"
        y2="4.51391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFB8F" />
        <stop offset="1" stopColor="#FFF500" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_7027_89920"
        x1="24.1428"
        y1="14.0178"
        x2="85.1985"
        y2="40.4988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1D900" />
        <stop offset="1" stopColor="#FFF500" />
      </linearGradient>
    </defs>
  </svg>

);

export default PreloaderBorder;
