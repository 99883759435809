import React,
{
  FC, useEffect, useMemo, useState,
} from 'react';
import BigCoin from 'icons/BigCoin.svg';
import Logo from 'icons/Logo.svg';
import MobLogo from 'icons/mobLogo.svg';
import { useTranslation } from 'react-i18next';
import useResize from 'hooks/useResize';
import Styled from './styled';

const Header: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 900, [windowWidth]);
  const [setMenu, setMenuActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const handleMenuClick = (): void => {
    setMenuActive(true);
  };
  const closeMenu = (): void => {
    setMenuActive(false);
  };

  useEffect(() => {
    const handleScroll = (): void => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Styled.Container scrolled={scrolled}>
      {mobileView
        ? (
          <>
            <Styled.Bg active={setMenu} onClick={closeMenu} />
            <Styled.Menu active={setMenu}>
              <Styled.MenuCloseIcon onClick={closeMenu} />
              <Styled.Logo href="/">
                <MobLogo />
              </Styled.Logo>
              <Styled.Nav>
                <Styled.Link href="https://discord.gg/HTU3BpFNc3" target="_blank">{t('HEADER_community')}</Styled.Link>
                <Styled.Link href="https://dao.roobee.finance/" target="_blank">
                  {t('HEADER_governance')}
                </Styled.Link>
                <Styled.Link href="https://coinmarketcap.com/community/profile/Roobee" target="_blank">
                  {t('HEADER_blog')}
                </Styled.Link>
                <Styled.Link
                  href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                  target="_blank"
                >
                  {t('HEADER_faq')}
                </Styled.Link>
              </Styled.Nav>
            </Styled.Menu>
          </>
        ) : null}
      {mobileView
        ? (
          <Styled.MobWrapper active={setMenu} scrolled={scrolled}>
            <Styled.MenuIcon onClick={handleMenuClick} />
            <Styled.Button white href="http://app.roobee.finance/" target="_blank">{t('BUTTON_launch')}</Styled.Button>
          </Styled.MobWrapper>
        )
        : (
          <Styled.Wrapper>
            <Styled.Logo href="/">
              <BigCoin />
              <Logo />
            </Styled.Logo>
            <Styled.Nav>
              <Styled.Link href="https://discord.gg/HTU3BpFNc3" target="_blank">{t('HEADER_community')}</Styled.Link>
              <Styled.Link href="https://dao.roobee.finance/" target="_blank">
                {t('HEADER_governance')}
              </Styled.Link>
              <Styled.Link href="https://coinmarketcap.com/community/profile/Roobee" target="_blank">
                {t('HEADER_blog')}
              </Styled.Link>
              <Styled.Link
                href="https://roobee.gitbook.io/roobee-eng/the-roobee-token/key-information"
                target="_blank"
              >
                {t('HEADER_faq')}
              </Styled.Link>
            </Styled.Nav>
            <Styled.Button white href="http://app.roobee.finance/" target="_blank">{t('BUTTON_launch')}</Styled.Button>
          </Styled.Wrapper>
        )}
    </Styled.Container>
  );
};

export default Header;
