import styled from 'styled-components';

const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  max-width: 1240px;
  margin: 0 auto 10px;
  color: #000000;
`;

export default Title;
