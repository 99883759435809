import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Marketplace:FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);
  const ref = useRef<HTMLElement>(null);
  const [animationInitialized, setAnimationInitialized] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (ref.current) {
        const screenCenter = window.scrollY + window.innerHeight / 2;
        const sectionTargetHeight = ref.current.offsetTop + ref.current.offsetHeight * 0.25;
        if (screenCenter > sectionTargetHeight) {
          setAnimationInitialized(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Wrapper ref={ref}>
      <Styled.Container>
        <Styled.Box>
          <Styled.Title>{t('MARKETPLACE_title')}</Styled.Title>
          <Styled.Subtitle>{t('MARKETPLACE_subtitle')}</Styled.Subtitle>
        </Styled.Box>
        <Styled.Box>
          {!mobileView
            ? (
              <Styled.AnimatedBox>
                <Styled.Desktop src="./assets/images/marketplace/desktop.png" />
                <Styled.AnimatedImg
                  src="./assets/images/marketplace/bg.svg"
                  animated={animationInitialized}
                  alt=""
                  index={1}
                />
                <Styled.AnimatedImg
                  src="./assets/images/marketplace/bg.svg"
                  animated={animationInitialized}
                  alt=""
                  index={2}
                />
                <Styled.AnimatedImg
                  src="./assets/images/marketplace/bg.svg"
                  animated={animationInitialized}
                  alt=""
                  index={3}
                />
              </Styled.AnimatedBox>
            )
            : <Styled.Desktop src="./assets/images/marketplace/desktop_tablet.png" />}
          <Styled.Button href="https://app.roobee.finance/invest" target="_blank">
            {t('BUTTON_marketplace')}
          </Styled.Button>
        </Styled.Box>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Marketplace;
