import styled, { keyframes } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 60px;
  
`;

const Container = styled(NotStyledContainer)`
  padding: 0 100px;
  @media (max-width: 1350px) {
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

const Box = styled.div`
  &:last-of-type {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  @media (max-width: 1350px) {
    &:last-of-type {
      flex-direction: column;
    }
  }
`;

const Desktop = styled.img`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  margin-top: 60px;
  @media (max-width: 1350px) {
    margin-top: 0;
    box-shadow: unset;
  }
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  color: ${Colors.black};
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  max-width: 610px;
  margin: 10px 0 0;
  @media (max-width: 1350px) {
    margin: 10px 0 0;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Button = styled(NotStyledButton)`
`;

const fly = keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(-20px);
  }
  30% {
    transform: translateY(-30px);
  }
  40% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(-50px);
  }
  60% {
    transform: translateY(-40px);
  }
  70% {
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(-20px);
  }
  90% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const BGleft = styled.img`
  position: absolute;
  top: 40%;
  left: 19%;
  z-index: -1;
  animation: fly 5s infinite;
  
  @keyframes fly { 
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
}
  }
  @media (max-width: 1350px) {
    display: none;
  }
`;

const BGright = styled.img`
  position: absolute;
  top: 14%;
  left: 63%;
  z-index: -1;
  animation: fly 5s infinite ease-in-out;
  animation-delay: 1s;
  @media (max-width: 1350px) {
    display: none;
  }
`;

export default {
  Wrapper,
  Container,
  Box,
  Desktop,
  Title,
  Subtitle,
  Button,
  BGleft,
  BGright,
  fly,
};
