import React, { FC } from 'react';

const AuroraIcon:FC = () => (
  <svg width="88" height="87" viewBox="0 0 88 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44.0121" cy="43.0144" r="33.6757" transform="rotate(19.5814 44.0121 43.0144)" fill="#6ED34A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M51.5738 24.0969L51.1215 23.936C49.665 23.4179 48.0407 23.7695 46.9288 24.8436L26.7674 44.3203C26.3156 44.7568 25.9709 45.2919 25.7603 45.8838L25.6162 46.289C24.8627 48.4072 25.9691 50.7351 28.0873 51.4886L52.1477 60.0474C54.2659 60.8009 56.5939 59.6945 57.3474 57.5763L57.4867 57.1846C57.6989 56.588 57.7691 55.9502 57.6915 55.3217L54.2496 27.4336C54.0609 25.9047 53.0252 24.6132 51.5738 24.0969ZM52.2495 20.7647C49.5887 19.8182 46.6214 20.4605 44.5902 22.4227L24.4287 41.8995C23.6033 42.6968 22.9737 43.6744 22.5891 44.7557L22.4449 45.1609C21.0684 49.0305 23.0895 53.2834 26.9592 54.6599L51.0197 63.2187C54.8893 64.5952 59.1422 62.5741 60.5187 58.7044L60.658 58.3127C61.0457 57.2228 61.1738 56.0575 61.0321 54.9094L57.5902 27.0213C57.2455 24.2281 55.3535 21.8688 52.7019 20.9256L52.2495 20.7647Z" fill="white" />
  </svg>

);

export default AuroraIcon;
