import styled, { css } from 'styled-components';
import NotStyledRoobeeCoin from 'icons/BigCoin.svg';
import NotStyledMetamaskIcon from 'icons/metamask.svg';
import NotStyledAppleStoreIcon from 'icons/applestore.svg';
import NotStyledGooglePlayIcon from 'icons/googlestore.svg';
import NotStyledWarningIcon from 'icons/warning.svg';
import NotStyledLinkIcon from 'icons/linkicon.svg';
import NotStyledArrow from 'icons/arrow-right.svg';
import NotStyledRoobee from 'icons/Roobee.svg';
import NotStyledFinance from 'icons/finance.svg';
import { Link as NotStyledLink } from 'react-router-dom';

const Wrapper = styled.div``;
const Header = styled.div`
  background: #F6FDFE;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #D8E4EB;
`;
const RoobeeIcon = styled(NotStyledRoobeeCoin)`
  width: 26px;
  height: 25px;
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #000000;
`;
const Container = styled.div`
  padding: 135px 30px 60px 20px;
  max-width: 850px;
  margin: 0 auto;
`;
const WithBorder = styled.a<{padding?: string; margin?: string;}>`
  background: #FFFFFF;
  border: 2px solid #FFA000;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => props.margin && css`margin: ${props.margin}`};
  ${(props) => props.padding && css`padding: ${props.padding}`};
  &:last-of-type {
    cursor: default;
  }
`;
const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  text-align: center;
  color: #5C5C5C;
`;
const Step = styled.div<{margin?: string;}>`
  font-style: normal;
  font-weight: 700;
  font-family: "Inter";
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  ${(props) => props.margin && css`margin: ${props.margin}`};
`;
const MetamaskIcon = styled(NotStyledMetamaskIcon)`
  margin-top: 13px;
`;
const MobileMarket = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const MarketItem = styled.a`
  display: flex;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 400;
    font-family: "Inter";
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #000000;
    margin-left: 10px;
  }
`;
const AppleStoreIcon = styled(NotStyledAppleStoreIcon)`
`;
const WarningIcon = styled(NotStyledWarningIcon)`
  margin-bottom: 10px;
`;
const GooglePlayIcon = styled(NotStyledGooglePlayIcon)`
`;
const List = styled.ul`
  margin: 0 auto;
`;
const ListItem = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
  font-family: "Inter";
`;

const Image = styled.img<{margin?: string}>`
  ${(props) => props.margin && css`margin: ${props.margin}`};
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 500px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const Link = styled(NotStyledLink)`
  background: #F0F5F7;
  border: 1px solid #A8F0F6;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 11px 11px 11px 20px;
  max-width: 170px;
  font-family: "Inter";
  justify-content: space-between;
`;

const LinkIcon = styled(NotStyledLinkIcon)``;
const Faq = styled.div`
  padding: 20px 20px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const FaqText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 16px;
`;
const FaqLink = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #000000;
  margin-bottom: 13px;
  display: block;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const ListVariable = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

const GoBack = styled(NotStyledLink)`
  padding: 20px 0;
  display: flex;
  align-items: center;
`;
const ArrowLeft = styled(NotStyledArrow)`
  transform: rotate(-180deg);
  margin-right: 12px;
  path {
    fill: #8596A6;
  }
`;
const RoobeeFooterIcon = styled(NotStyledRoobeeCoin)`
  width: 26px;
  height: 25px;
  margin-right: 5px;
`;
const RoobeeLogo = styled(NotStyledRoobee)`
  margin-right: 9px;
`;
const RoobeeFinance = styled(NotStyledFinance)``;

const FaqContent = styled.div`
  max-width: 1240px;
  width: 100%;
`;

export default {
  Wrapper,
  Image,
  Link,
  Header,
  WithBorder,
  Text,
  List,
  ListVariable,
  ListItem,
  MetamaskIcon,
  Container,
  MobileMarket,
  GoBack,
  ArrowLeft,
  RoobeeFooterIcon,
  RoobeeLogo,
  RoobeeFinance,
  WarningIcon,
  LinkIcon,
  AppleStoreIcon,
  MarketItem,
  GooglePlayIcon,
  Step,
  RoobeeIcon,
  Title,
  Faq,
  FaqText,
  FaqLink,
  FaqContent,
};
