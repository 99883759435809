import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';

type TokensProps = {
  roobeeTokenWebsites: any[]
  roobeeTokenCards: any[]
}

const cardsPerRow = 3;

const Tokens: FC<TokensProps> = ({ roobeeTokenWebsites, roobeeTokenCards }) => {
  const { t } = useTranslation();

  const rowCards = useMemo(() => {
    if (roobeeTokenCards?.length) {
      const cards: any[] = [];

      let i; let
        j;
      for (i = 0, j = roobeeTokenCards.length; i < j; i += cardsPerRow) {
        cards.push(roobeeTokenCards.slice(i, i + cardsPerRow));
      }

      return cards;
    }
    return [];
  }, [roobeeTokenCards]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>{t('TOKENS_title')}</Styled.Title>
          <Styled.Websites>
            {roobeeTokenWebsites.map((website: any, index: number) => (
              <Styled.HrBox key={`${website.title}-link`}>
                <Styled.WebsitesLink
                  target="_blank"
                  href={website.url}
                >
                  {t(`tokens.markets.${website.title}`)}
                </Styled.WebsitesLink>
                {index !== roobeeTokenWebsites.length - 1 && (
                <Styled.WebsitesHr>
                  |
                </Styled.WebsitesHr>
                )}
              </Styled.HrBox>
            ))}
          </Styled.Websites>
          <Styled.Cards>
            {rowCards.map((cards, index) => (
              <Styled.CardsRow key={`row-${index}`}>
                {cards.map((card: any, cardIndex: number) => (
                  <Styled.CardsRowLink
                    key={`row-${index}-${cardIndex}`}
                    target="_blank"
                    href={card.url}
                    slug={card.slug}
                  >
                    <Styled.CardsImage
                      src={`/assets/images/tokens/${card.slug}${card.isSvg ? '.svg' : '.png'}`}
                      alt={card.title}
                    />
                  </Styled.CardsRowLink>
                ))}
              </Styled.CardsRow>
            ))}
          </Styled.Cards>
        </Styled.Content>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Tokens;
