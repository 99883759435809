import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import Styled from './styled';

const CookiesPrivacyPolicy:() => JSX.Element | null = () => {
  const [cookiesPrivacyPolicyAccepted, setCookiesPrivacyAccepted] = useState(
    localStorage.getItem('cookies_accepted') === 'true',
  );
  const onAccept = useCallback((): any => {
    setCookiesPrivacyAccepted(true);
    localStorage.setItem('cookies_accepted', 'true');
  }, []);

  const { t } = useTranslation();

  return (
    !cookiesPrivacyPolicyAccepted ? (
      <Styled.Wrapper>
        <Styled.Container>
          <div>
            <Styled.Text>{Parser(t('COOKIES_PRIVACY_POLICY'))}</Styled.Text>
            <Styled.Text>{Parser(t('COOKIES_PRIVACY_POLICY_SUB'))}</Styled.Text>
          </div>
          <Styled.Button onClick={onAccept}>{t('BUTTON_accept')}</Styled.Button>
        </Styled.Container>
      </Styled.Wrapper>
    )
      : null
  );
};

export default CookiesPrivacyPolicy;
