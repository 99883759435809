import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 60px;
  
  @media (max-width: 1350px) {
    margin: 0 auto;
  }
  
`;

const Container = styled(NotStyledContainer)`
`;

const Box = styled.div`
  &:last-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 1350px) {
    &:last-of-type {
      flex-direction: column;
    }
  }
`;

const Desktop = styled.img`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  margin-top: 60px;
  @media (max-width: 1350px) {
    margin-top: -25px;
    box-shadow: unset;
  }
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  text-align: end;
  color: ${Colors.black};
  @media (max-width: 600px) {
    text-align: start;
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  max-width: 892px;
  text-align: end;
  margin: 20px 0 0 auto;
  @media (max-width: 1350px) {
    margin: 20px 0 0 auto;
  }
  @media (max-width: 600px) {
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Button = styled(NotStyledButton)`
`;

const AnimatedImg = styled.img<{ index: number }>`

  ${(props) => {
    if (props.index === 1) {
      return css`
        position: absolute;
        top: 8%;
        left: 3%;
        z-index: -1;
        animation: fly 5s infinite ease-in-out;
        @media (max-width: 1350px) {
          display: none;
        }
      `;
    }
    if (props.index === 2) {
      return css`
        position: absolute;
        top: 40%;
        left: 50%;
        z-index: -1;
        animation: fly 5s infinite ease-in-out;
        animation-delay: 1s;
        @media (max-width: 1350px) {
          display: none;
        }
      `;
    }

    return '';
  }}
`;

export default {
  Wrapper,
  Container,
  Box,
  Desktop,
  Title,
  Subtitle,
  Button,
  AnimatedImg,
};
