import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';
import { default as NotStyledArbitrumIcon } from './_resources/Arbitrum';
import { default as NotStyledAuroraIcon } from './_resources/Aurora';
import { default as NotStyledAvalancheIcon } from './_resources/Avalanche';
import { default as NotStyledBitgertIcon } from './_resources/Bitgert';
import { default as NotStyledEthereumIcon } from './_resources/Ethereum';
import { default as NotStyledBNBIcon } from './_resources/BNB';
import { default as NotStyledOptimismIcon } from './_resources/Optimism';
import { default as NotStyledPolygonIcon } from './_resources/Polygon';

type ShowTypes = {
  show?: boolean
}

const Wrapper = styled.section`
  width: 100%;
  overflow: hidden;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
`;

const Container = styled(NotStyledContainer)<ShowTypes>`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 140px auto 0;
  @media (max-width: 900px) {
    margin: 100px auto 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/hero/hero_bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: ${(props) => (props.show ? '1' : '0')};
    transition: opacity 2s ease-in-out;
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    top: -30%;
    right: -30%;
    width: 900px;
    height: 900px;
    background-image: url("./assets/images/hero/hero_blue_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;

    @media (max-width: 1350px) {
      content: unset;
    }
  }
`;

const Title = styled.h1<ShowTypes>`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 80px;
  text-align: center;
  max-width: 808px;
  margin: 0 auto;
  color: ${Colors.black};
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  @media (max-width: 600px) {
    color: #000;
    text-align: center;
    font-size: 37px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    max-width: 300px;
  }
  
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }

  ${(props) => props.show && css`
      span:nth-child(1) {
        animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(2) {
        animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(3) {
        animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(4) {
        animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(5) {
        animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(6) {
        animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(7) {
        animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
    `};
  
  @keyframes fade-in {
    100% {
      opacity: 0.5;
      filter: blur(0);
    }
  }
  
`;

const Subtitle = styled.h2<ShowTypes>`
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${Colors.black};
  max-width: 504px;
  margin: 40px auto 0;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  @media (max-width: 600px) {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 280px;
    margin: 20px auto 0;
  }

  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }

  ${(props) => props.show && css`
    span:nth-child(1) {
      animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(2) {
      animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(3) {
      animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(4) {
      animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(5) {
      animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(6) {
      animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(7) {
      animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(8) {
      animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(9) {
      animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(10) {
      animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(11) {
      animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
  `};

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
`;

const Box = styled.div`
  max-width: 320px;
  margin: 0 auto;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  @media (max-width: 600px) {
    margin-top: 30px;
  }
`;

const Button = styled(NotStyledButton)`
  margin: 0;
  &:first-of-type {
    margin-right: 10px;
  }
  @media (max-width: 600px) {
    max-width: 150px;
    height: 32px;
  }
`;

const IconBox = styled.div`
  display: flex;
  border: 1px solid #C2C2C2;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 600px) {
    max-width: 200px;
    margin: 50px auto 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 78px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const IconText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #A5A5A5;
  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const Icons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: center;
`;

const Icon = styled.img`
  min-width: 30px;
  min-height: 30px;
  opacity: 70%;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 1;
  }
`;

const Link = styled.a`
  display: flex;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const CoinBox = styled.div<ShowTypes>`
  position: absolute;
  transform: translate(-50%, -50%);
  opacity: 0;

  @keyframes fadeInRotate {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5) rotate(0deg);
      filter: blur(20px);
    }
    50% {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1.2) rotate(180deg);
      filter: blur(10px);
    }
    100% {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1) rotate(360deg);
      filter: blur(0);
    }
  }

  @keyframes flyRotate {
    0% {
      transform: translateY(-10px) rotate(0deg);
    }
    50% {
      transform: translateY(10px) rotate(180deg);
    }
    100% {
      transform: translateY(-10px) rotate(360deg);
    }
  }
  

  ${(props) => props.show && css`

    animation: fadeInRotate 2s ease forwards, flyRotate 7s linear infinite;
    
    &:nth-child(1) {
      animation-delay: 1s;
      top: 10%;
      left: 10%;
    }

    &:nth-child(2) {
      animation-delay: 1.5s;
      top: 48%;
      left: 20%;
    }

    &:nth-child(3) {
      animation-delay: 2s;
      top: 68%;
      left: 12%;
    }

    &:nth-child(4) {
      animation-delay: 2.5s;
      top: 48%;
      left: 8%;
    }

    &:nth-child(5) {
      animation-delay: 3s;
      top: 21%;
      right: 5%;
    }

    &:nth-child(6) {
      animation-delay: 3.5s;
      top: 45%;
      right: 15%;
    }

    &:nth-child(7) {
      animation-delay: 4s;
      top: 60%;
      right: 6%;
    }

    &:nth-child(8) {
      animation-delay: 4.5s;
      top: 80%;
      right: 17%;
    }
  `}
  
`;

const Arbitrum = styled(NotStyledArbitrumIcon)`
  
`;

const Aurora = styled(NotStyledAuroraIcon)`

`;

const Avalanche = styled(NotStyledAvalancheIcon)`

`;

const Bitgert = styled(NotStyledBitgertIcon)`

`;

const Ethereum = styled(NotStyledEthereumIcon)`

`;

const Optimism = styled(NotStyledOptimismIcon)`

`;

const BNB = styled(NotStyledBNBIcon)`

`;

const Polygon = styled(NotStyledPolygonIcon)`

`;

export default {
  Wrapper,
  Container,
  Title,
  Subtitle,
  Box,
  ButtonBox,
  Button,
  IconBox,
  Icon,
  Icons,
  Link,
  IconWrapper,
  IconText,
  Arbitrum,
  Aurora,
  Avalanche,
  Bitgert,
  Polygon,
  BNB,
  Ethereum,
  Optimism,
  CoinBox,
};
