import styled, { css } from 'styled-components';
import { default as NotStyledTitle } from 'styles/elements/title';

const Wrapper = styled.section`
  margin-top: 200px;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    display: block;
    top: 5%;
    left: -48%;
    background: url(/assets/images/section-backgrounds/Eco-Background-mob.png) no-repeat 100% 100%;
    height: 100%;
    width: 100%;
  }
  
  @media (max-width: 1440px) {
    margin-top: 100px;
  }
  
  
  @media (max-width: 900px) {
    padding-top: 40px;
    margin-top: 40px;
    padding-bottom: 60px;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      background: url("/assets/images/section-backgrounds/Eco-Background-mob.png") no-repeat 60% 65%;
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 40px 20px;
    margin-top: 0;
  }
  @media (max-width: 500px) {
    &:after {
      background: url("/assets/images/section-backgrounds/Eco-Background-mob.png") no-repeat 51% 53%
    }
  }
`;
const Desktop = styled.div`
  z-index: 1;
  position: relative;
  height: 672px;
  margin-top: 46px;
  
  @media (max-width: 1350px) {
    overflow: hidden;
  }

  @media (max-width: 850px) {
    margin-top: -50px;
  }
  
  @media (max-width: 800px) {
    height: 810px;
  }
  
  @media (max-width: 600px) {
    height: 625px;
    overflow: visible;
  }
  
  @media (max-width: 500px) {
    height: 300px;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }

  &:before {
    background-image: url('/assets/images/Eco/net.svg');
    background-repeat: no-repeat;
    width: 2090px;
    height: 1200px;
    transform: translate(-550px, -46%);
    opacity: .8;
    transition: transform .5s ease, width .5s ease, height .5s ease;

    @media (max-width: 1600px) {
      width: 1900px;
    }
    @media (max-width: 1250px) {
      width: 1650px;
    }
    @media (max-width: 1000px) {
      transform: translate(-700px, -46%);
    }
    @media (max-width: 850px) {
      width: 1500px;
    }
    @media (max-width: 800px) {
      transform: translate(-200px,-45%);
      background-image: url('/assets/images/Eco/netmob.png');
      height: 900px;
    }
    
    @media (max-width: 600px) {
      width: 520px;
      transform: translate(-40px,-46%);
      background-image: url('/assets/images/Eco/netmob.png');
    }
    
    @media (max-width: 500px) {
      width: 492px;
      transform: translate(-100px,-46%);
      background-image: url('/assets/images/Eco/netmob.png');
    }
    
    @media (max-width: 400px) {
      width: 400px;
      transform: translate(-100px,-46%);
    }
    
    @media (max-width: 350px) {
      width: 300px;
    }
  }

  &:after {
    background-image: url('/assets/images/Eco/roobee-coin.png');
    background-repeat: no-repeat;
    width: 360px;
    height: 377px;
    transform: translate(-75px, -50%);
    transition: width .5s ease, height .5s ease;
    @media (max-width: 1250px) {
      width: 250px;
      height: 280px;
    }
    @media (max-width: 1000px) {
      width: 200px;
      height: 220px;
    }
    @media (max-width: 850px) {
      width: 190px;
    }
    @media (max-width: 800px) {
      width: 195px;
      transform: translate(-95px,-38%);
      height: 230px;
    }
    
    @media (max-width: 600px) {
      transform: translate(-85px,-26%);
      height: 150px;
    }

    @media (max-width: 500px) {
      transform: translate(-55px,-26%);
      height: 115px;
      width: 90px;
    }
    
  }

  @media (max-width: 900px) {
    
  }

`;
const Icon = styled.img`
  display: block;
  
  @media (max-width: 800px) {
    max-width: 50px;
    max-height: 50px;
  }
  
  @media (max-width: 600px) {
    max-width: 40px;
    max-height: 40px;
  }
  
  @media (max-width: 500px) {
    max-width: 30px;
    max-height: 30px;
  }
  
  @media (max-width: 350px) {
    max-width: 20px;
    max-height: 20px;
  }
  
`;
const Item = styled.div<{
  rocket?: boolean;
  earth?: boolean;
  star?: boolean;
  wallet?: boolean;
  nft?: boolean;
  profile?: boolean;
}>`
  position: absolute;
  z-index: 1;
  transition: top .5s ease, left .5s ease;

  ${(props) => props.rocket && css`
    top: 80px; 
    left: 500px;
    @media (max-width: 1600px) {
      top: 115px;
    }
    @media (max-width: 1250px) {
      top: 140px;
      left: 390px;
    }
    @media (max-width: 1000px) {
      top: 155px;
      left: 200px;
    }
    @media (max-width: 850px) {
      top: 180px;
      left: 160px;
    }
    @media (max-width: 800px) {
      top: 160px;
      left: 169px;
    }
    @media (max-width: 600px) {
      top: 107px;
      left: 134px;
    }
    @media (max-width: 500px) {
      top: 22px;
      left: 150px;
    }
    @media (max-width: 400px) {
      top: 53px;
      left: 100px;
    }
    @media (max-width: 350px) {
      top: 89px;
      left: 50px;
    }
  `}
  ${(props) => props.earth && css`
    left: 900px;
    @media (max-width: 1600px) {
      top: 40px;
      left: 800px;
    }
    @media (max-width: 1250px) {
      top: 80px;
      left: 620px;
    }
    @media (max-width: 1000px) {
      top: 95px;
      left: 500px;
    }
    @media (max-width: 850px) {
      top: 120px;
      left: 380px;
    }
    @media (max-width: 800px) {
      top: 110px;
      left: 338px;
    }
    @media (max-width: 600px) {
      top: 130px;
      left: 280px;
    }
    @media (max-width: 500px) {
      top: 0;
      left: 255px;
    }
    @media (max-width: 400px) {
      top: 33px;
      left: 175px;
    }
    @media (max-width: 350px) {
      top: 70px;
      left: 111px;
    }
  `}
  ${(props) => props.star && css`
    left: 828px; 
    top: 210px;
    @media (max-width: 1600px) {
      top: 280px;
      left: 676px;
    }
    @media (max-width: 1250px) {
      top: 300px;
      left: 500px;
    }
    @media (max-width: 1000px) {
      left: 380px;
    }
    @media (max-width: 850px) {
      left: 280px;
    }
    @media (max-width: 800px) {
      top: 425px;
      left: 370px;
    }
    @media (max-width: 600px) {
      top: 330px;
      left: 304px;
    }
    @media (max-width: 500px) {
      top: 130px;
      left: 247px;
    }
    @media (max-width: 400px) {
      top: 140px;
      left: 178px;
    }
    @media (max-width: 350px) {
      top: 185px;
      left: 95px;
    }
  `}
  ${(props) => props.wallet && css`
    left: 1250px; 
    top: 190px;
    @media (max-width: 1600px) {
      top: 230px;
      left: 1075px;
    }
    @media (max-width: 1250px) {
      left: 855px;
    }
    @media (max-width: 1000px) {
      left: 720px;
    }
    @media (max-width: 850px) {
      left: 590px;
    }
    @media (max-width: 800px) {
      left: 560px;
      top: 340px;
    }
    @media (max-width: 600px) {
      left: 424px;
      top: 278px;
    }
    @media (max-width: 500px) {
      left: 345px;
      top: 130px;
    }
    @media (max-width: 400px) {
      left: 258px;
      top: 138px;
    }
    @media (max-width: 350px) {
      left: 170px;
      top: 150px;
    }
  `}
  ${(props) => props.nft && css`
    left: 400px;
    top: 480px;
    @media (max-width: 1600px) {
      top: 437px;
    }
    @media (max-width: 1250px) {
      left: 350px;
      top: 395px;
    }
    @media (max-width: 1000px) {
      left: 235px;
    }
    @media (max-width: 850px) {
      top: 375px;
      left: 185px;
    }
    @media (max-width: 800px) {
      top: 355px;
      left: 213px;
    }
    @media (max-width: 600px) {
      top: 276px;
      left: 210px;
    }
    @media (max-width: 500px) {
      top: 209px;
      left: 96px;
    }
    @media (max-width: 400px) {
      top: 180px;
      left: 80px;
    }
    @media (max-width: 350px) {
      top: 155px;
      left: 45px;
    }
  `}
  ${(props) => props.profile && css`
    top: 430px;
    left: 1000px;
    @media (max-width: 1600px) {
      top: 115px;
    }
    @media (max-width: 1250px) {
      left: 725px;
      top: 380px;
    }
    @media (max-width: 1000px) {
      left: 605px;
    }
    @media (max-width: 850px) {
      left: 480px;
    }
    @media (max-width: 800px) {
      left: 203px;
      top: 700px;
    }
    @media (max-width: 600px) {
      left: 235px;
      top: 490px;
    }
    @media (max-width: 500px) {
      left: 230px;
      top: 295px;
    }
    @media (max-width: 400px) {
      left: 157px;
      top: 275px;
    }
    @media (max-width: 350px) {
      left: 70px;
      top: 265px;
    }
  `}
`;
const Descr = styled.span`
  position: absolute;
  bottom: -14px;
  transform: translateY(100%);
  width: 171px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
  opacity: 0.5;
  @media (max-width: 800px) {
    width: 120px;
  }
  
  @media (max-width: 600px) {
    font-size: 12px;
    width: 86px;
    line-height: 12px;
  }
  
  @media (max-width: 500px) {
    font-size: 4.88226px;
    line-height: 7px;
    bottom: -3px;
    width: 35px;
  }
`;

const Block = styled.div`
  @media (max-width: 900px) {
    position: relative;
    margin-bottom: 56px;

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      &:after {
        background-image: url('/assets/images/Eco/right-coin.png');
        width: 110px;
        height: 148px;
        right: -30px;
      }

      ul {
        padding-right: 90px;
      }
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;

      &:after {
        background-image: url('/assets/images/Eco/roobee-coin-mob.png');
        width: 65px;
        height: 65px;
        left: -24px;
      }

      ul {
        padding-left: 75px;
        padding-right: 16px;
      }
    }
  }
`;
const List = styled.ul`
  @media (max-width: 900px) {
    padding-left: 28px;
  }
`;
const ListItem = styled.li`
  @media (max-width: 900px) {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6F7270;
    opacity: 0.9;
    position: relative;
    
    &:before {
      position: absolute;
      top: 50%;
      left: -28px;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/assets/images/checkmark.svg');
      width: 20px;
      height: 20px;
      content: '';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Text = styled.span`
  @media (max-width: 900px) {
    word-break: break-word;
  }
`;

const Title = styled(NotStyledTitle)`
  font-family: 'Gruppo',sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 65px;
  color: #000000;
  padding: 0 100px;
  margin: 0;
  max-width: unset;
  @media (max-width: 1350px) {
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 40px;
    padding: 100px 0;
  }
  @media (max-width: 350px) {
    padding: 50px 0;
  }
`;

export default {
  Wrapper,
  Desktop,
  Item,
  Block,
  List,
  Title,
  ListItem,
  Text,
  Icon,
  Descr,
};
