import styled, { css } from 'styled-components';
import colors from '../colors';

interface ButtonProps {
  white?: boolean
}

const Button = styled.a<ButtonProps>`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
  max-width: 160px;
  width: 100%;
  background: linear-gradient(90deg, #B6E82F 0%, #50E0ED 100%);
  height: 40px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: ${colors.black};
  text-transform: initial;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 3s ease;
  margin-top: 40px;
  @media (max-width: 1350px) {
    margin: 25px auto 0;
  }
  
  &:hover { 
    transition: all 3s ease;
    background: linear-gradient(90deg, rgba(182, 232, 47, 0.25) 0%, rgba(80, 224, 237, 0.25) 100%);
  }
  
  ${(props) => props.white && css`
    border: solid 1px transparent;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), 
    linear-gradient(101deg, #B6E82F, #50E0ED);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #F8F8F8 inset;
    border-radius: 10px;
    transition: color, border, box-shadow, filter 0.3s ease;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    &:hover {
      border: none;
      background: linear-gradient(90deg, rgba(182, 232, 47, 0.25) 0%, rgba(80, 224, 237, 0.25) 100%);
      transition: all 0.3s ease;
      box-shadow: unset;
    };
  `}
`;

export default Button;
