import React, { FC } from 'react';

const ArbitrumIcon:FC = () => (
  <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="68.3013" cy="68.3013" r="50" transform="rotate(-30 68.3013 68.3013)" fill="#E84142" />
    <path d="M70.9901 89.8458L53.5036 99.9416C52.9737 100.248 52.3105 99.8696 52.3037 99.2577L51.6488 40.8339C51.6418 40.2157 52.3088 39.8236 52.8456 40.1303L70.261 50.0815C71.1765 50.6045 71.7501 51.5698 71.772 52.624L72.4895 87.1854C72.5122 88.2793 71.9376 89.2987 70.9901 89.8458Z" fill="white" />
    <path d="M82.125 57.7021L103.239 70.0178C103.703 70.2886 103.701 70.9599 103.236 71.2286L82.2311 83.3557C81.7657 83.6245 81.1835 83.2901 81.1812 82.7526L81.0723 58.3099C81.0699 57.7683 81.6571 57.4292 82.125 57.7021Z" fill="white" />
  </svg>

);

export default ArbitrumIcon;
