import React, { FC } from 'react';

const PreloaderSymbol:FC = (props) => (
  <svg {...props} width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1742 9.73559V0.177246H0.585938V41.8243H11.1742V21.3421L33.0565 8.37011L28.1153 0.177246L11.1742 9.73559Z" fill="black" />
  </svg>

);

export default PreloaderSymbol;
