import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  margin-top: 65px;
  position: relative;
`;

const Container = styled(NotStyledContainer)`
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  text-align: end;
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  color: ${Colors.black};
  @media (max-width: 600px) {
    text-align: start;
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  max-width: 610px;
  margin-top: 20px;
  
  @media (max-width: 1350px) {
    margin-left: auto;
  }
  @media (max-width: 600px) {
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Button = styled(NotStyledButton)`
  margin-left: auto;
`;

const MobileImg = styled.img`
  position: relative;
  z-index: 3;
  @media (max-width: 1350px) {
    display: flex;
    margin: 15px auto 0;
  }
  @media (max-width: 600px) {
    max-height: 300px;
  }
`;

const IconBox = styled.div<{ index: number }>`
  position: absolute;
  
  &:after {
    content: '';
    background: #F8F8F8;
    position: absolute;   
  }
  
  ${(props) => {
    if (props.index === 1) {
      return css`
        left: -100px;
        top: 20%;
        width: 86px;
        height: 459px;
        
        &:after {
          top: 100%;
          bottom: -2px;
          left: -1px;
          right: calc(100% - 3px);
          animation: icon1anim 5s linear infinite;
        }
        
        @keyframes icon1anim {
          0% {
            top: 100%;
            bottom: -2px;
            right: calc(100% - 3px);
            left: -1px;
          }
          20% {
            top: -2px;
            bottom: -2px;
            right: calc(100% - 3px);
            left: -1px;
          }
          40% {
            top: -2px;
            bottom: -2px;
            right: -1px;
            left: -1px;
          }
          60% {
            top: -2px;
            bottom: -2px;
            right: -1px;
            left: -1px;
          }
          80% {
            top: -2px;
            bottom: calc(100% - 2px);
            right: -1px;
            left: -1px;
          }
          100% {
            top: -2px;
            bottom: calc(100% - 2px);
            right: -1px;
            left: calc(100% + 1px);
          }
        }
      `;
    }
    if (props.index === 2) {
      return css`
        top: 13%;
        left: 400px;
        width: 87px;
        height: 203px;

        &:after {
          top: -2px;
          bottom: calc(100% + 2px);
          left: calc(100% - 2px);
          right: -2px;
          animation: icon2anim 5s linear infinite 2s;
        }
        
        @keyframes icon2anim {
          0% {
            top: -2px;
            bottom: calc(100% + 2px);
            left: calc(100% - 2px);
            right: -2px;
          }
          20% {
            top: -2px;
            bottom: -2px;
            left: calc(100% - 2px);
            right: -2px;
          }
          40% {
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
          60% {
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
          80% {
            top: calc(100% - 2px);
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
          100% {
            top: calc(100% - 2px);
            bottom: -2px;
            left: -2px;
            right: calc(100% + 2px);
          }
        }
      `;
    }
    if (props.index === 3) {
      return css`
        bottom: 10%;
        left: 350px;
        width: 596px;
        height: 101px;

        &:after {
          top: calc(100% + 2px);
          bottom: -2px;
          left: calc(100% - 2px);
          right: -2px;
          animation: icon3anim 5s linear infinite;
        }

        @keyframes icon3anim {
          0% {
            top: calc(100% + 2px);
            bottom: -2px;
            left: calc(100% - 2px);
            right: -2px;
          }
          25% {
            top: -2px;
            bottom: -2px;
            left: calc(100% - 2px);
            right: -2px;
          }
          40% {
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
          60% {
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
          80% {
            top: -2px;
            bottom: calc(100% - 2px);
            left: -2px;
            right: calc(100% + 2px);
          }
          100% {
            top: -2px;
            bottom: calc(100% - 2px);
            left: -2px;
            right: calc(100% + 2px);
          }
        }
      `;
    }
    return '';
  }}
`;

const AnimationBox = styled.div`
  position: relative;
`;

export default {
  Wrapper,
  Container,
  Content,
  Box,
  Title,
  Subtitle,
  MobileImg,
  Button,
  IconBox,
  AnimationBox,
};
