import { css } from 'styled-components';

const Fonts = css`

  @font-face {
    font-family: "Gruppo";
    src: url("/assets/fonts/Gruppo-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Geometria";
    src: url("/assets/fonts/Geometria.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: "Geometria";
    src: url("/assets/fonts/Geometria-ExtraLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: "Geometria";
    src: url("/assets/fonts/Geometria-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "Geometria";
    src: url("/assets/fonts/Geometria-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "Geometria";
    src: url("/assets/fonts/Geometria-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
  }
  @font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
  }
`;

export default Fonts;
