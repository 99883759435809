import React, { FC } from 'react';

const EthereumIcon:FC = () => (
  <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="51.998" cy="51.9984" r="37" transform="rotate(38 51.998 51.9984)" fill="#627EEA" />
    <path d="M38.3927 41.3657L68.704 30.6148L56.5549 46.1649L38.3927 41.3657Z" fill="white" />
    <path d="M56.5541 46.1673L68.7031 30.6172L65.6045 62.629L56.5541 46.1673Z" fill="#C0CBF6" />
    <path d="M38.3933 41.3625L56.5555 46.1617L45.9251 59.768L38.3933 41.3625Z" fill="#C0CBF6" />
    <path d="M65.6043 62.6282L56.5538 46.1665L45.9234 59.7728L65.6043 62.6282Z" fill="#8197EE" />
    <path d="M35.2926 73.3774L36.1133 44.2812L43.6451 62.6867L35.2926 73.3774Z" fill="white" />
    <path d="M63.329 65.5445L35.2957 73.3798L43.6481 62.6891L63.329 65.5445Z" fill="#C0CBF6" />
  </svg>

);

export default EthereumIcon;
