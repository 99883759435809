import styled, { css } from 'styled-components';

const Top = styled.div`
  position: relative;
  height: 374px;
  padding: 0 100px;
  @media (max-width: 1350px) {
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
  @media (max-width: 650px) {
    height: 474px;
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 50px;
  line-height: 55px;
  max-width: 567px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 0;
    max-width: 274px;
    font-size: 35px;
    line-height: 40px;
  }
`;
const Wrapper = styled.footer`
`;
const Icon = styled.img`
  min-width: 20px;
  max-width: 20px;
  margin-right: 18px;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Column = styled.div`
  margin-right: 115px;
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 900px) {
    margin-right: 10%;
  }
`;

const ColumnName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 85%;
  display: flex;
  align-items: center;
  color: #5C5C5C;
  margin-bottom: 25px;
`;

const Link = styled.a<{mail?: boolean;}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 85%;
  margin-right: 24px;
  text-decoration-line: underline;
  color: #697B8C;
  ${(props) => props.mail && css`
    text-decoration-line: none;
    @media (max-width:900px) {
      display: none;
    }
  `};
  &:hover {
    font-weight: 700;
  }
  @media (max-width:900px) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 85%;
    text-decoration-line: underline;
    color: #697B8C;
    margin-right: 0;
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;
const Mail = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 85%;
  color: #697B8C;
`;
const Copyright = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 85%;
  color: #858585;
  margin: 40px 0;
  @media (max-width: 600px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 85%;
    color: #858585;
  }
`;
const TopContainer = styled.div`
  border-top: 0.5px solid #C2C2C2;
  border-bottom: 0.5px solid #C2C2C2;
  padding: 40px 0;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  @media (max-width:900px) {
   padding: 50px 20px;
  }
`;
const Bottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 370px;

  &:after, &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 370px;
  }

  &:after {
    z-index: -2;
    background: no-repeat url("/assets/images/section-backgrounds/Footer-Background-Bottom.png") center;
    background-size: cover;
  }

  &:before {
    z-index: -1;
    background: no-repeat url("/assets/images/footer/Footer-Bottom-Stickers.png") bottom;
  }
  
  @media (max-width: 440px) {
    height: 320px;
    
    
    &:after {
      z-index: -2;
      background: no-repeat url("/assets/images/section-backgrounds/Footer-Background-Bottom-Mob.png") center;
      background-size: cover;
    }
    
    &:before {
      z-index: -1;
      background-image: url("/assets/images/footer/Footer-Bottom-Stickers-mob.png");
      background-repeat: no-repeat;
    }
  }
`;

const Social = styled.a`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 85%;
    align-items: center;
    color: #858585;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(182, 232, 47, 0.25) 0%, rgba(80, 224, 237, 0.25) 100%);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
      transform-origin: 0 50%;
    }
  }

  & span:hover:after {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
`;
const ButtonText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-right: 12px;
  color: #F5F5F5;
`;

const DiscordButton = styled.a`
  border-radius: 9px;
  padding: 12px 29px;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
  linear-gradient(101deg, #B6E82F, #50E0ED);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #577EE2 inset;
  transition: color, border, box-shadow, filter 0.3s ease;
  text-align: center;
  justify-content: center;
  &:hover {
    background: linear-gradient(90deg, #B6E82F 0%, #50E0ED 100%);
    transition: all 0.3s ease;
    box-shadow: unset;
    ${ButtonText} {
      color: #000000;
      
    }
    svg {
      path {
        fill: #000000
      }
    }
  };
  
`;

export default {
  Wrapper,
  Text,
  Top,
  Social,
  Column,
  ColumnName,
  Icon,
  ButtonText,
  DiscordButton,
  Box,
  Link,
  Mail,
  Copyright,
  Bottom,
  TopContainer,
};
