import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';

type WalletProps = {
  data: WalletTypes[]
}

type WalletTypes = {
  icon: string,
  text: string,
  link: string,
}

const Wallet:FC<WalletProps> = ({ data }) => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 600, [windowWidth]);
  const ref = useRef<HTMLElement>(null);
  const [animationInitialized, setAnimationInitialized] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (ref.current) {
        const screenCenter = window.scrollY + window.innerHeight / 2;
        const sectionTargetHeight = ref.current.offsetTop + ref.current.offsetHeight * 0.25;
        if (screenCenter > sectionTargetHeight) {
          setAnimationInitialized(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Wrapper ref={ref}>
      <Styled.Container>
        <Styled.Box>
          <Styled.Title>{t('WALLETS_title')}</Styled.Title>
          <Styled.Subtitle>{t('WALLETS_subtitle')}</Styled.Subtitle>
        </Styled.Box>
        <Styled.Box>
          <Styled.IconsWrapper>
            {data.map((item) => (
              <Styled.IconBox>
                <a target="_blank" href={`${item.link}`} rel="noreferrer">
                  <img alt="#" src={`${item.icon}`} />
                  <span>{item.text}</span>
                </a>
              </Styled.IconBox>
            ))}
            {!mobileView
              ? (
                <Styled.AnimatedImg
                  src="./assets/images/wallet/bg.svg"
                  animated={animationInitialized}
                />
              )
              : null}
          </Styled.IconsWrapper>
          <Styled.Button href="https://app.roobee.finance/" target="_blank">{t('BUTTON_wallets')}</Styled.Button>
        </Styled.Box>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Wallet;
