import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Save: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  const ref = useRef<HTMLElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (ref.current) {
        const screenCenter = window.scrollY + window.innerHeight / 2;
        const sectionTargetHeight = ref.current.offsetTop + ref.current.offsetHeight * 0.25;
        if (screenCenter > sectionTargetHeight) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Wrapper ref={ref}>
      <Styled.Container>
        {mobileView ? (
          <Styled.Content>
            <Styled.Box>
              <Styled.Title>{t('SAVE_title')}</Styled.Title>
              <Styled.Subtitle>{t('SAVE_subtitle')}</Styled.Subtitle>
            </Styled.Box>
            <Styled.Box>
              <Styled.MobileImg src="./assets/images/save/mobile_tablet.png" />
              <Styled.Button href="https://app.roobee.finance/save" target="_blank">{t('BUTTON_save')}</Styled.Button>
            </Styled.Box>
          </Styled.Content>
        ) : (
          <Styled.Content>
            <Styled.AnimatedBox>
              <Styled.MobileImg src="./assets/images/save/mobile.png" />
              <Styled.AnimatedImg
                src="./assets/images/save/bottom_1.svg"
                animated={isScrolling}
                alt=""
                index={1}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/bottom_2.svg"
                animated={isScrolling}
                alt=""
                index={2}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/bottom_3.svg"
                animated={isScrolling}
                alt=""
                index={3}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/right_1.svg"
                animated={isScrolling}
                alt=""
                index={4}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/right_2.svg"
                animated={isScrolling}
                alt=""
                index={5}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/top_1.svg"
                animated={isScrolling}
                alt=""
                index={6}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/top_1.svg"
                animated={isScrolling}
                alt=""
                index={7}
                isScrollingDown={isScrolling}
              />
              <Styled.AnimatedImg
                src="./assets/images/save/top_1.svg"
                animated={isScrolling}
                alt=""
                index={8}
                isScrollingDown={isScrolling}
              />
            </Styled.AnimatedBox>
            <Styled.Box>
              <Styled.Title>{t('SAVE_title')}</Styled.Title>
              <Styled.Subtitle>{t('SAVE_subtitle')}</Styled.Subtitle>
              <Styled.Button href="https://app.roobee.finance/save" target="_blank">{t('BUTTON_save')}</Styled.Button>
            </Styled.Box>
          </Styled.Content>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Save;
