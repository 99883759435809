import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Container = styled(NotStyledContainer)`
  position: relative;
`;

const Box = styled.div`
  @media (max-width: 1350px) {
    &:last-of-type {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  color: ${Colors.black};
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  max-width: 500px;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
    max-width: 280px;
    margin-top: 10px;
  }
`;

const Button = styled(NotStyledButton)`
`;

const GrayBg = styled.img<{ index: number }>`
  position: absolute;

  ${(props) => {
    if (props.index === 1) {
      return css`
        right: 95px;
        top: 195px;
      `;
    }
    if (props.index === 2) {
      return css`
        left: 335px;
        top: 370px;
      `;
    }
    return '';
  }};
  
  @media (max-width: 1350px) {
    position: unset;
    width: 100%;
  }
`;

const AnimatedImg = styled.img<{ index: number, animated: boolean }>`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  
  ${(props) => {
    if (props.index === 1) {
      return css`
        left: 23.5%;
        transition: opacity 0.5s ease;
        
        ${props.animated && css`
          top: 137%;
          opacity: 1;
          animation: fly-1 10s linear infinite;
        `}
      `;
    }
    if (props.index === 2) {
      return css`
        right: 7%;
        transition: opacity 0.5s ease;

        ${props.animated && css`
          top: 55%;
          opacity: 1;
          animation: fly-2 10s linear infinite;
        `}
      `;
    }
    return '';
  }};

  @keyframes fly-1 {
    0% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-50px);
    }
  };

  @keyframes fly-2 {
    0% {
      transform: translateY(50px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(50px);
    }
  }
`;

const Wrapper = styled.section`
  margin-top: 180px;
  min-height: 750px;
  
  @media (max-width: 1350px) {
    min-height: unset;
    margin-top: 100px;
  }
  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;

export default {
  Wrapper,
  Container,
  Box,
  Title,
  Subtitle,
  GrayBg,
  AnimatedImg,
  Button,
};
