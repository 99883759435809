const HeroContent = [
  {
    link: 'https://dappradar.com/multichain/defi/roobee-finance',
    icon: '/assets/images/hero/dappradar.svg',
    id: 1,
  },
  {
    link: 'https://zealy.io/c/roobee',
    icon: '/assets/images/hero/zealy.svg',
    id: 2,
  },
  {
    link: 'https://coinmarketcap.com/currencies/roobee/',
    icon: '/assets/images/hero/coinmarketcap.svg',
    id: 3,
  },
];

export {
  HeroContent,
};
