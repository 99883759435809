import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'containers/Footer';
import Styled from './styled';

const HowToInstall: FC = () => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.RoobeeIcon />
        <Styled.Title>{t('HOW_TO_INSTALL-title')}</Styled.Title>
      </Styled.Header>
      <Styled.Container>
        <Styled.Step margin="0 0 17px">1) Install Metamask APP for your device</Styled.Step>
        <Styled.WithBorder
          href="https://metamask.app.link/skAH3BaF99"
          target="_blank"
          margin="0 0 36px"
          padding="19px 18px 10px"
        >
          <Styled.Text>We recommend using the most popular crypto wallet MetaMask.</Styled.Text>
          <Styled.MetamaskIcon />
        </Styled.WithBorder>
        <Styled.MobileMarket>
          <Styled.MarketItem
            href="https://metamask.app.link/skAH3BaF99"
            target="_blank"
          >
            <Styled.AppleStoreIcon />
            <span>APPstore</span>
          </Styled.MarketItem>
          <Styled.MarketItem
            href="https://metamask.app.link/skAH3BaF99"
            target="_blank"
          >
            <Styled.GooglePlayIcon />
            <span>Google play</span>
          </Styled.MarketItem>
        </Styled.MobileMarket>
        <Styled.Step margin="0 0 13px">2) Set up your wallet</Styled.Step>
        <Styled.List>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Styled.ListItem>2.1) click "Create a new wallet"</Styled.ListItem>
          <Styled.ListItem>2.2) create a password</Styled.ListItem>
          <Styled.ListItem>2.3) generate sid-phrase</Styled.ListItem>
          <Styled.WithBorder margin="20px 0" padding="10px 48px 20px">
            <Styled.WarningIcon />
            <Styled.Text>
              save the phrase in a safe place otherwise you may
              losing access to the wallet forever
            </Styled.Text>
          </Styled.WithBorder>
          <Styled.ListItem>2.4) Done! Your wallet ready to use</Styled.ListItem>
        </Styled.List>
        <Styled.Step margin="38px 0 8px">3) Go to Roobee.Finance</Styled.Step>
        <Styled.List>
          <Styled.ListItem>3.1) open Metamask on your device</Styled.ListItem>
          <Styled.ListItem>3.2) click menu (upper left corner)</Styled.ListItem>
          <Styled.ListItem>3.3) open “Browser”:</Styled.ListItem>
          <Styled.Image margin="28px 0 30px" src="/assets/images/howtoinstall/image1.png" />
          <Styled.ListItem>3.4) input roobee.finance</Styled.ListItem>
          <Styled.Link to="/">
            <span>roobee.finance</span>
            <Styled.LinkIcon />
          </Styled.Link>
          <Styled.Image margin="40px 0" src="/assets/images/howtoinstall/image2.png" />
          <Styled.ListItem>3.5) click button “LAUNCH APP”</Styled.ListItem>
          <Styled.Image margin="20px 0 40px" src="/assets/images/howtoinstall/image3.png" />
          <Styled.ListItem>3.6) accept “terms of use” and choose network:</Styled.ListItem>
          <Styled.Image margin="22px 0 30px" src="/assets/images/howtoinstall/image6.png" />
          <Styled.ListItem>3.7) click Metamask (if you don’t see this step go to the next one)</Styled.ListItem>
          <Styled.Image margin="22px 0 30px" src="/assets/images/howtoinstall/image7.png" />
          <Styled.ListItem>
            3.8) It’s done! Now you are ready to make a profit with the Roobee.Finance!
          </Styled.ListItem>
          <Styled.Image margin="20px 0 0" src="/assets/images/howtoinstall/image8.png" />
        </Styled.List>
      </Styled.Container>
      <Styled.Faq>
        <Styled.FaqContent>
          <Styled.FaqText>FAQ</Styled.FaqText>
          <Styled.FaqLink
            target="_blank"
            href="https://metamask.io/faqs/"
          >
            https://metamask.io/faqs/
          </Styled.FaqLink>
          <Styled.FaqLink
            target="_blank"
            href="https://roobee.gitbook.io/roobee-eng/"
          >
            https://roobee.gitbook.io/roobee-eng/
          </Styled.FaqLink>
          <Styled.GoBack to="/">
            <Styled.ArrowLeft />
            <Styled.RoobeeFooterIcon />
            <Styled.RoobeeLogo />
            <Styled.RoobeeFinance />
          </Styled.GoBack>
        </Styled.FaqContent>
      </Styled.Faq>
      <Footer />
    </Styled.Wrapper>
  );
};

export default HowToInstall;
