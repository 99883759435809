import styled from 'styled-components';
import LinkIcon from 'icons/link.svg';

const Item = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #E3E3E3;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 32px;
`;
const ItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;

`;
const Avatar = styled.img`
  height: 32px;
  object-fit: contain;
`;
const Date = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #6F7270;
`;
const Text = styled.p`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #181818;
`;
const Link = styled.a`
  width: 27px;
  height: 28px;
  object-fit: contain;
  margin-top: auto;
  display: block;
`;
const SVG = styled(LinkIcon)`
  path {
    fill: #6F7270;
    stroke: #6F7270;
    transition: stroke .2s ease, fill .2s ease;
  }

  &:hover {
    path {
      fill: #181818;
      stroke: #181818;
    }
  }
`;
const Wrapper = styled.div`
  margin-bottom: 91px;
  overflow: hidden;
  
  @media (max-width: 900px) {
    margin-top: 30px;
    margin-bottom: 45px;
  }
  .news__pagination {
    display: flex;
    justify-content: center;
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  .news__pagination .swiper-pagination-bullet {
    margin-right: 6px;
    width: 25px;
    height: 0;
    background: 0 0;
    padding: 24px 0;
    transition: opacity .2s ease;
    &:after {
      content: "";
      width: 100%;
      height: 0;
      display: block;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
    }
  }
  .swiper-container {
    width: 100%;
    overflow: visible;
  }
  .swiper-slide {
    width: 380px;
    height: auto;
  }
`;
const Container = styled.div`
  padding: 0 100px;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1350px) {
    padding: 0 50px;
  }
  @media (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;
const Title = styled.h2`
  font-family: 'Gruppo', sans-serif;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 60px;
  line-height: 65px;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
const Cards = styled.div`
  margin-top: 67px;
`;

export default {
  Wrapper,
  Container,
  Title,
  Cards,
  Item,
  ItemTop,
  Avatar,
  Date,
  Text,
  Link,
  SVG,
};
