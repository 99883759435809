import styled, { css } from 'styled-components';
import { default as NotStyledContainer } from 'styles/elements/container';
import { default as NotStyledButton } from 'styles/elements/button';
import Colors from '../../styles/colors';

const Wrapper = styled.section`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 60px;
`;

const Container = styled(NotStyledContainer)`
`;

const Box = styled.div`
  &:last-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 1350px) {
    &:last-of-type {
      flex-direction: column;
    }
  }
`;

const Desktop = styled.img`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  margin-top: 60px;
  position: relative;
  z-index: 10;
  @media (max-width: 1350px) {
    margin-top: 0;
    box-shadow: unset;
  }
`;

const Title = styled.h1`
  font-family: 'Gruppo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 65px;
  text-align: end;
  color: ${Colors.black};
  @media (max-width: 600px) {
    text-align: start;
    font-size: 40px;
    line-height: 40px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #5C5C5C;
  text-align: end;
  margin: 20px 0 0;
  @media (max-width: 600px) {
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Button = styled(NotStyledButton)`
  max-width: 150px;
  width: 150px;
  @media (max-width: 1350px) {
    margin: 30px auto 0;
  }
`;

const AnimatedBox = styled.div`
  position: relative;
`;

const AnimatedImg = styled.img<{ index: number, animated: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-out;

  ${(props) => {
    if (props.index === 1) {
      return css`
        bottom: 40%;
        left: 92%;
        max-width: 232px;
        z-index: 3;
        transform: rotate(-70deg);
        ${props.animated && css`
          animation: hash-1 20s linear infinite;
        `}
        
      `;
    }
    if (props.index === 2) {
      return css`
        bottom: -15%;
        left: 113%;
        max-width: 171px;
        transform: rotate(-130deg);
        z-index: 2;
        ${props.animated && css`
          animation: hash-2 20s linear infinite;
        `}
      `;
    }
    if (props.index === 3) {
      return css`
        bottom: -16%;
        left: 6%;
        max-width: 232px;
        transform: rotate(0);
        z-index: 1;
        ${props.animated && css`
          animation: hash-3 20s linear infinite;
        `}
        
      `;
    }
    return '';
  }};

  @keyframes hash-1 {
    0% {
      transform: rotate(-70deg);
    }
    100% {
      transform: rotate(360deg);
    }
  };

  @keyframes hash-2 {
    0% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(360deg);
    }
  };

  @keyframes hash-3 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  };
`;

export default {
  Wrapper,
  Container,
  Box,
  Desktop,
  Title,
  Subtitle,
  Button,
  AnimatedBox,
  AnimatedImg,
};
