import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px 137px 25px 100px;
  background: linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 0) 100%);
  border: 2px solid rgba(107, 251, 147, 0.81);
  z-index: 9999;
  transform: translateY(0);
  transition: transform .3s ease;
  .policy {
    display: inline-block;
    margin-left: 5px;
    text-decoration-line: underline;
  }
  @media (max-width: 900px) {
    padding: 20px;
  }
  
  @media (max-width: 1100px) {
    padding: 25px 40px 25px 40px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Text = styled.p`
  display: inline-block;
  max-width: 778px;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.2px;
  color: #5C5C5C;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 24px;
  }
  
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Button = styled.button`
  padding: 9px 42px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  width: 160px;
  height: 40px;
  left: 1182px;
  top: calc(50% - 40px/2);


  background: linear-gradient(90deg, #B6E82F 0%, #50E0ED 100%);
  border-radius: 10px;
  &:hover {
    transition: all 3s ease;
    background: linear-gradient(90deg, rgba(182, 232, 47, 0.25) 0%, rgba(80, 224, 237, 0.25) 100%);
  }
  @media (max-width: 900px) {
    width: 160px;
  }
`;

export default {
  Wrapper,
  Container,
  Text,
  Button,
};
