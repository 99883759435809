import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';
import FirstVector from './_resources/FirstVector';
import SecondVector from './_resources/SecondVector';
import ThirdVector from './_resources/ThirdVector';

const DCA: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        {mobileView ? (
          <Styled.Content>
            <Styled.Box>
              <Styled.Title>{t('DCA_title')}</Styled.Title>
              <Styled.Subtitle>{t('DCA_subtitle')}</Styled.Subtitle>
            </Styled.Box>
            <Styled.Box>
              <Styled.MobileImg src="./assets/images/dca/mobile_tablet.png" />
              <Styled.Button href="https://app.roobee.finance/dca" target="_blank">{t('BUTTON_dca')}</Styled.Button>
            </Styled.Box>
          </Styled.Content>
        ) : (
          <Styled.Content>
            <Styled.AnimationBox>
              <Styled.MobileImg src="./assets/images/dca/mobile.png" />
              <Styled.IconBox index={1}>
                <FirstVector />
              </Styled.IconBox>
              <Styled.IconBox index={2}>
                <SecondVector />
              </Styled.IconBox>
              <Styled.IconBox index={3}>
                <ThirdVector />
              </Styled.IconBox>
            </Styled.AnimationBox>
            <Styled.Box>
              <Styled.Title>{t('DCA_title')}</Styled.Title>
              <Styled.Subtitle>{t('DCA_subtitle')}</Styled.Subtitle>
              <Styled.Button href="https://app.roobee.finance/dca" target="_blank">{t('BUTTON_dca')}</Styled.Button>
            </Styled.Box>
          </Styled.Content>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default DCA;
