const WalletContent = [
  {
    icon: '/assets/images/wallet/Metamask.svg',
    text: 'Metamask',
    link: 'https://metamask.io/',
  },
  {
    icon: '/assets/images/wallet/Onto.svg',
    text: 'ONTO wallet',
    link: 'https://onto.app/',
  },
  {
    icon: '/assets/images/wallet/SafePal.svg',
    text: 'SafePal',
    link: 'https://www.safepal.io/',
  },
  {
    icon: '/assets/images/wallet/Math.svg',
    text: 'Math wallet',
    link: 'https://mathwallet.org/',
  },
  {
    icon: '/assets/images/wallet/WalletConnect.svg',
    text: 'WalletConnect',
    link: 'https://walletconnect.com/',
  },
  {
    icon: '/assets/images/wallet/TrustWallet.svg',
    text: 'Trust Wallet',
    link: 'https://trustwallet.com/',
  },
  {
    icon: '/assets/images/wallet/BeetKeep.svg',
    text: 'BitKeep',
    link: 'https://bitkeep.com/',
  },
  {
    icon: '/assets/images/wallet/Binance.svg',
    text: 'Binance Chain Wallet',
    link: 'https://chrome.google.com/webstore/detail/binance-wallet/fhbohimaelbohpjbbldcngcnapndodjp',
  },
];

export {
  WalletContent,
};
