import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled';
import useResize from '../../hooks/useResize';

const Networks: FC = () => {
  const { t } = useTranslation();
  const { windowWidth } = useResize();
  const ref = useRef<HTMLElement>(null);
  const [animationInitialized, setAnimationInitialized] = useState(false);

  const mobileView = useMemo(() => windowWidth <= 1350, [windowWidth]);

  useEffect(() => {
    const handleScroll = (): void => {
      if (ref.current) {
        const screenCenter = window.scrollY + window.innerHeight / 2;
        const sectionTargetHeight = ref.current.offsetTop + ref.current.offsetHeight * 0.25;
        if (screenCenter > sectionTargetHeight) {
          setAnimationInitialized(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Wrapper ref={ref}>
      {mobileView ? (
        <Styled.Container>
          <Styled.Box>
            <Styled.Title>{t('NETWORK_title')}</Styled.Title>
            <Styled.Subtitle>{t('NETWORK_subtitle')}</Styled.Subtitle>
          </Styled.Box>
          <Styled.Box>
            <Styled.GrayBg src="./assets/images/networks/bg_tablet.png" index={1} />
            <Styled.Button href="https://app.roobee.finance/" target="_blank">{t('BUTTON_try')}</Styled.Button>
          </Styled.Box>
        </Styled.Container>
      ) : (
        <Styled.Container>
          <Styled.Box>
            <Styled.Title>{t('NETWORK_title')}</Styled.Title>
            <Styled.Subtitle>{t('NETWORK_subtitle')}</Styled.Subtitle>
            <Styled.Button href="https://app.roobee.finance/" target="_blank">{t('BUTTON_try')}</Styled.Button>
          </Styled.Box>
          <Styled.GrayBg src="./assets/images/networks/gray_bg.png" index={1} />
          <Styled.GrayBg src="./assets/images/networks/gray_bg.png" index={2} />
          <Styled.AnimatedImg
            src="./assets/images/networks/networks_icon_one.png"
            index={1}
            animated={animationInitialized}
          />
          <Styled.AnimatedImg
            src="./assets/images/networks/networks_icon_two.png"
            index={2}
            animated={animationInitialized}
          />
        </Styled.Container>
      )}

    </Styled.Wrapper>
  );
};

export default Networks;
